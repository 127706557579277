import React from 'react'

function trade_entity_registration() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Trade entity registration
</h1>

<div className="inline-block p-4">
<p>
All motor traders such as Importers, Exporters, Manufacturers and Traders must be registered on MalTIS as Trade Entities.
  </p>
<h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>Remember that you have to be enrolled on MalTIS before registering as a trade entity. For instructions on how to enroll please click here: NATURAL PERSONS or BODY OF PERSONS
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='w-fit bg-blue-800 w-100% text-white rounded-md p-2 text-sm m-4'>DIFFERENT TYPES OF TRADE ENTITIES:</h4>
        <li>Builder/Manufacturer</li>
        <li>
        Importer
        </li>
        <li>
        New vehicle importer
        </li>
        <li>
        2nd Hand Vehicle Importer -- Indigenous Malawian (Malawian citizens)
        </li>
        <li>2nd Hand Vehicle Importer -- Other (Foreigners or Organisations)
        <li>
        For own use: Natural Persons - Maximum of 5 vehicles per year OR Body of Persons: Ownership of vehicles cannot be changed within 12 months of registration
          </li>
          </li>

        <li>Exporter</li>
        <li>Car Hire Companies</li>
        <li>Motor Trader/ Dealers (to qualify for motor trade numbers)</li>

        <h4 className='bg-blue-800 w-100% text-whitew-fit  rounded-md p-1 text-sm mt-2'>CHECKLIST FOR TRADE ENTITY REGISTRATION. DO YOU HAVE?</h4>

        <li>Traffic Register Card/Certificate of Entity (Natural Person or Body of Persons)</li>
        <li>
        Supporting Documentation:
        </li>
        <li>
        Police report
        </li>
        <li>
        MRA VAT Returns
        </li>
        <li>Insurance</li>
        <li>ODPP Registration Certificate</li>
        <li>Completed INFRASTRUCTURE REGISTRATION FORM.</li>

        <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-1 text-sm mt-2'>IMPORTER FOR OWN USE REGISTRATION PROCESS</h4>

        <li>To avoid filling in forms in the queue, download and print the INFRASTRUCTURE REGISTRATION. form now and complete it before you arrive at the DRTSS office.</li>
        <li>
        Visit your nearest DRTSS OFFICE.
        </li>
        <li>
        Go to the Vehicle Applications Office
        </li>
        <li>
        Submit the registration form and supporting documentation.
        </li>

        <li>
        A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>
        Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.
          </li>
          </li>

          <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
          <li>Go to the Document Issuing office to collect your Trade Entity Certificate.</li>

          <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-1 text-sm mt-2'>ALL OTHER TRADE ENTITY TYPES</h4>

          <li>To avoid filling in forms in the queue, download the INFRASTRUCTURE REGISTRATION form now and complete it before you arrive at the DRTSS office.</li>
        <li>
        Visit your nearest DRTSS OFFICE.
        </li>
        <li>
        Go to the Vehicle Applications Office
        </li>
        <li>
        Submit the registration form and supporting documentation.
        </li>

        <li>
        A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>
        Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.
          </li>
          </li>

          <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
          <li>Upon completion of the inspection, the DRTSS Officer will indicate to you whether or not you have passed. If the inspection was passed, you may return to the DRTSS Offices.</li>
          <li>Go to the Vehicle Applications Office.</li>
          <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.</li>
          <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
          <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
          <li>Go to the Document Issuing office to collect your Trade Entity Certificate.</li>
          <li>Your registration, unless canceled or suspended, will be valid for 12 months. Please renew your registration before the expiry date at a reduced fee.</li>
  </div>  
  </div>
  )
}

export default trade_entity_registration
