import React from 'react'

function CoreValues() {
  return (
    <div className='w-full bg-[#1e0e66] py-6 px-4 text-white'>
                <h1 className='text-white-600 font-bold text-center text-4xl'>Core Values</h1>
            
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-3'>
        <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>
          <h1 className='text-[20px] font-bold text-center'> Proactive and Timeliness in Responding to Situations</h1>     
                    <p className='p-3 text-bold text-justify'> We shall endeavor to standout of the situation in the course of discharging our duties instead of reacting to situations in responding to our clients’ needs. </p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>  
                  <h1 className='text-[20px] font-bold text-center'> Proffessionalism</h1>   
                    <p className='p-3 text-bold text-justify'> We shall discharge our duties with high integrity and following standard procedures using highly qualified, competent and skilled staff.</p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>   
                  <h1 className='text-[20px] font-bold text-center'> Transparency and Accountablity</h1>  
                    <p className='p-3 text-bold text-justify'>We shall discharge our duties in an open manner and shall be accountable for all our actions to our stakeholders</p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'> 
                  <h1 className='text-[20px] font-bold text-center'> Customer Centric</h1>    
                    <p className='p-3 text-bold text-justify'> We shall treat our clients as kings and shall endeavor to provide demand-driven services that will exceed their expectations</p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'> 
                  <h1 className='text-[20px] font-bold text-center'>Creativity and Innovativeness</h1>    
                    <p className='p-3 text-bold text-justify'>We shall endeavor to create a conducive environment for our members of staff to be creative and innovative to improve performance and delivery of quality services to all road users</p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>  
                  <h1 className='text-[20px] font-bold text-center'> Fairness</h1>   
                    <p className='p-3 text-bold text-justify'>We shall discharge our duties based on the principle of justice and fairness where all our clients will be treated fairly, with honesty and respect following established rules and laws</p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'> 
                  <h1 className='text-[20px] font-bold text-center'> Environmental Conscious</h1>    
                    <p className='p-3 text-bold text-justify'> We shall at all times be mindful of environmental protection </p>
                  </div>
                  
        </div>    
    </div>
  )
}

export default CoreValues
