import React from 'react'

function proffessional_driving_application() {
  return (
    <div>
      <h1 className="p-4 font-semibold text-2xl">Professional Driving Permit (PrDP) Application:</h1>
  
  <div className="inline-block p-4">
  <p>
    Thus, the conveyance of passengers or goods without the benefit of reward does not require a PrDP. However, the benefit of reward is defined as being employed to convey passengers or goods or doing so to further one’s own agenda/business.
    </p>
  <h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>Remember that you have to be enrolled on MalTIS before applying to schedule a PrDP. For instructions on how to enroll please click here: NATURAL PERSONS
</h4>
  </div>

  <div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
  <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-2 text-sm m-4'>PRDP IS REQUIRED IF YOU DRIVE:</h4>
          <li>Passengers for Reward, </li>
          <li>Goods for Reward, or</li>
          <li>Dangerous Goods for Reward.</li>
         
          <p className='bg-blue-800 w-100% text-white p-2 m-4 w-fit'>
          AGE RESTRICTIONS FOR PRDP:
        </p>

          <li>
          Goods and Passengers PrDP: 21 years of age.
          </li>
          <li>
          Dangerous Goods PrDP: 25 years of age.
          </li>
          <li>
          If you are 65 years old you will not be allowed to apply for a PrDP.
          </li>
          <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-2 text-sm m-4'>CHECKLIST FOR PROFESSIONAL DRIVING PERMIT APPLICATION. DO YOU HAVE?</h4>
          <li>Traffic Register Card - received when you enroll on MalTIS;</li>
          <li>A valid Driving License that corresponds to the correct Vehicle Category.
             <li>Note * to apply for a PrDP you must have been in possession of a Malawian Driving Licence for a period longer than 24 months.</li>
             <li>Goods and Dangerous Goods PrDP: "C" or "B" range Licenses 
            <li>
              With a license Category of either B1, B, EB, C1, EC1, C or EC.
              </li>
          </li>
          <li>
          Passengers PrDP:
          <li>
          With a license Category of either B1, B, EB, D1, ED1, D or ED.
          </li>
          </li>
          </li>
        
          <li>A MEDICAL CERTIFICATE completed by a Medical Practitioner, clearing you to drive.</li>
          <li>Completed PROFESSIONAL DRIVING PERMIT APPLICATION form.</li>
          <li>An eye-test might be conducted at the DRTSS office,
           should you have difficulty seeing and require glasses or contact lenses please remember to bring them with.
            Should you wish to visit your Optometrist instead, please let him/her complete a VISION CERTIFICATE to be verified by the DRESS Staff administering the eye test.</li>
          <h4 className='bg-yellow-100 w-fit text-yellow-600 rounded-md p-1 text-sm mt-2'>NAPPLICATION PROCESS</h4>

          <li>To avoid filling in forms in the queue,
           download and print the PROFESSIONAL DRIVING PERMIT APPLICATION form now and complete it before you arrive at the DRTSS office.
            medical certificate application form (if applicable).</li>
            <li>The Medical Certificate must be completed by a Medical Practitioner.</li>
            <li>Visit your nearest DRTSS OFFICE.</li>
            <li>Go to the Eye Test Station to undergo an Eye Test.
            <li>If you have already completed and passed an Eye Test at the DRTSS office within the past 6 (SIX) months, you will not be required to complete another Eye Test.</li>
            <li>If you have undergone an eye examination at an Optometrist you are not required to complete an Eye Test at the DRTSS. Note that a VISION CERTIFICATE must be submitted as proof of the examination.</li> 
            </li>

            <li>Submit your application at the Person Applications counter.</li>
            <li>Schedule a Professional Driving Permit test.</li>
            <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No monies are to be paid at the DRTSS office.
              <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
            </li>


            <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
            <li>Go to the Aptitude Examination Room where an Examiner will schedule your Aptitude Test for the PrDP.
</li>

<h1 className="p-4 font-semibold text-2xl">PrDP Test Day</h1>
<h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-1 text-sm mt-2'>CHECKLIST FOR TEST DAY. DO YOU HAVE?
</h4>

        <li>Traffic Register Card - received when you registered on MalTIS.</li>
        <li>A roadworthy vehicle of the appropriate vehicle category that will be used during the Road Test.</li>
        <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-1 text-sm mt-2'>TEST PROCESS</h4>
          <li>o to the DRTSS office where you have scheduled your PrDP test at. You should arrive at least 30 minutes before the scheduled test time.</li>
          <li>If all is in order, complete your Professional Driving Permit aptitude test.</li>
          <li>If you passed the Professional Driving Permit aptitude test, you will be allowed to complete the Road Test.</li>
          <li>If you pass the Road Test, a deposit slip will be printed, which indicates the AMOUNT to be paid at the bank. No monies are to be paid at the DRTSS office.
            <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
          
          </li>
          <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip, and collect your Professional Driving Permit.</li>

          <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-1 text-sm mt-2'><span className='font-medium'>Note</span> * Applicants that fail the Professional Driving Permit aptitude test, will NOT be allowed to continue with the Professional Driving Permit Road test. You will however have to wait 2 days before you will be allowed to reschedule a Professional Driving Permit test.
          <span className='font-medium'>Note</span> * Should you fail the Road Test, you will be able to reschedule both the Aptitude and Road Test following the same process. You will however have to wait 7 days before you will be allowed to reschedule a Professional Driving Permit test.
</h4>
    </div>  
    </div>
  )
}

export default proffessional_driving_application
