import React from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/footer';
import SideBar from '../Components/sideBar';
import { Outlet } from 'react-router-dom';

const Procedures = () => {
  return (
    <>
      <Nav />
      <SideBar />
        <div className="inline-block ml-[20%] w-4/5 text-white">
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default Procedures;
