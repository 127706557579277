import React from 'react'

function upliftment_of_suspended_permit() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Upliftment of suspended Operator registration or -permits</h1>

<div className="inline-block p-4">
<p>
An Operator registration can be suspended. The following process needs to be followed to uplift such a suspension.
  </p>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-1 text-sm mt-2'>CHECKLIST FOR UPLIFTING OF AN OPERATOR SUSPENSION. DO YOU HAVE?
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>

        <li>Traffic Register Card/Certificate of the Operator</li>
        <li>Completed APPLICATION FOR OPERATOR REGISTRATION form.</li>
        <h4 className='bg-blue-800 w-fit text-white rounded-md p-1 text-sm mt-2'>UPLIFTING PROCESS</h4>

        <li>Visit your nearest DRTSS OFFICE.</li>
        <li>Go to the Vehicle Applications Office or Vehicle Examiner’s Office.</li>
        <li>Submit your application form at the counter.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
            <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>

        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
        <li>Go to the Document Issuing office to collect your Operator Certificate.</li>
        
        
  </div>
  </div>
  )
}

export default upliftment_of_suspended_permit
