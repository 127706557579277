import React from 'react'

function public_service_registration_plates() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Public Service/Vanity Registration Plates Reservation</h1>
    
      <div className="inline-block p-4"> 
      <p>A person may apply to reserve an Out-Of-Sequence, Public Service or Vanity Registration Number for a period of 3 (THREE) months, or to have an already reserved number linked to a vehicle.</p>
      <h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Remember that you have to be enrolled on MalTIS before applying for a Public Service/Vanity Registration Number. For instructions on how to enroll please click here: NATURAL PERSONS / BODY OF PERSONS
        Public Service Number Plates can only be applied for at the DRTSS Head Office in Lilongwe.
      </h4>
      </div>

      <div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
      <h4 className='bg-blue-800 w-fit text-black rounded-md p-2 text-sm m-4'>CHECKLIST TO APPLY FOR THE RESERVATION OF A REGISTRATION NUMBER. DO YOU HAVE?</h4>
        <li>Traffic Register Card/Certificate of the vehicle title holder /owner.</li>
        <li>Completed PUBLIC SERVICE/VANITY REGISTRATION NUMBER APPLICATION form.</li>         
        <p className='bg-blue-800 p-2 m-4 w-fit'>
        RESERVATION PROCESS
      </p>

        <li>
        To avoid filling in forms in the queue, download and print the PUBLIC SERVICE/VANITY REGISTRATION NUMBER APPLICATION form now and complete it before you arrive at the DRTSS office.
        </li>
        <li>
        Visit your nearest DRTSS OFFICE.
        </li>
        <li>Go to the Vehicle Application Office.</li>
        <li>
        Submit your application at the counter.
        </li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
          <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once payment is made, the Public Service Registration Number will be reserved and you may return to the DRTSS office with the stamped deposit slip. Vanity numbers are subject to an approval process before the number is reserved. Approval may take up to 7 days.</li>
        <li>Go to the Document Issuing Office, where you may receive a Proof of Transaction/Receipt.</li>
        <p className='bg-blue-800 p-2 m-4 w-fit'>
        CHECKLIST FOR DUPLICATE CERTIFICATES. DO YOU HAVE?
      </p>
        <li>Traffic register Card/Certificate.</li>
        <li>Vehicle registration number.</li>
        <li>Completed VEHICLE REGISTRATION APPLICATION form.</li>
        <p className='bg-blue-800 p-2 m-4 w-fit'>APPLICATION PROCESS</p>
          <li>To avoid filling in forms in the queue, download and print the VEHICLE REGISTRATION APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
          <li>Visit your nearest DRTSS OFFICE.</li>
          <li>Go to the Vehicle Applications Office.</li>
          <li>Submit your application at the counter.</li>
          <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.</li>

        <p className='bg-blue-800 p-2 m-4 w-fit'>
        DEREGISTRATION PROCESS
      </p>

      <li>To avoid filling in forms in the queue, download and print the VEHICLE REGISTRATION APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
      <li>Visit your nearest DRTSS OFFICE.</li>
      <li>Go to the Vehicle Applications Office.</li>
      <li>Submit your application at the counter.</li>
      <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
      </li>
      <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
      <li>If the Title Holder is apply for the vehicle to be scrapped:
        <li>Scrapped vehicles require an inspection, where after an inspection officer will determine if the vehicle can be scrapped.</li>
        <li>Exported or Stolen applications do not require an inspection and can proceed to the next step.</li>
      </li>
      <li>Go to the Document Issuing office to collect your deregistration certificate.</li>

      <h1 className="p-4 font-semibold text-2xl">Linking a Registration Number to a Vehicle</h1>
      <h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>CHECKLIST TO APPLY FOR THE LINKING OF A REGISTRATION NUMBER TO A VEHICLE. DO YOU HAVE?
      </h4>
      <li>Traffic Register Card/Certificate of the vehicle title holder /owner (or proxy).</li>
      <li>Completed PUBLIC SERVICE/VANITY REGISTRATION NUMBER APPLICATION form.</li>
      <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
      REGISTRATION PROCESS
      </p>
      <li>To avoid filling in forms in the queue, download and print the PUBLIC SERVICE/VANITY REGISTRATION NUMBER APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
      <li>Visit your nearest DRTSS OFFICE.</li>
      <li>Go to the Vehicle Application Office.</li>
      <li>Submit your application at the counter.</li>
      <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
      </li>
      <li>Once payment is made, the Public Service/Vanity Registration Number will be registered to your vehicle and you may return to the DRTSS office with the stamped deposit slip.</li>
      <li>Go to the Document Issuing Office, where you will receive an authorization to purchase registration plates.</li>

  </div>  
  </div>
  )
}

export default public_service_registration_plates
