import React from 'react'

function change_of_vehicle() {
  return (
    <div>
      this is the change of vehicle page
    </div>
  )
}

export default change_of_vehicle
