import React,{useState} from 'react'
import Background from '../media/IMG_4271 - Copy.JPG'
import {Modal} from 'react-bootstrap'


function HeroSection() {
  const [show,setShow] = useState(false);

  const handleClose =()=> setShow(false);
  const handleShow =()=> setShow(true);
  return(
  <>
  <Modal
    show={show}
    onHide={handleClose}
    
    keyboard={false}
    centered
    size='xl'
  >
   <h1 className='p-4 '>
          <p className='text-center justify-center flex text-xl text-[#1e0e66] font-medium'>Contact information</p>
        </h1>
         <div className='w-full border flex flex-col p-4 my-4 rounded-lg border-[#1e0e66] border-1 text-[#1e0e66] font-medium'>     
                    <p className='p-3 text-bold text-center'> DRTSS Head Office
                         <p>Private Bag 257,</p> 
                          <p>Lilongwe.</p>
                          <p>Tel: 01 756 398/ 177/181/0995008482</p>
                          </p>
                  </div>
        <Modal.Body>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-3 text-[#1e0e66] font-medium'>
        <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 '>     
                    <p className='p-3 text-bold'> DRTSS Regional Office South,
                          <p>P.O. Box 30177,</p> 
                          <p>Blantyre 3. </p>
                          <p>Tel: 0995008502</p>
                          </p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>     
                    <p className='p-3 text-bold'> DRTSS Regional Office East,
                              <p>P.O. Box 315,</p>
                              <p>Zomba.</p>
                              <p>Tel: 01 527 955 / 0995008509</p>
                            
                    </p> 
                    {/* <div className='w-full bg-slate-200 h-full overflow-hidden  p-2 text-center'>wneifwnefionwefion</div> */}
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>     
                    <p className='p-3 text-bold'>DRTSS Regional Office Centre,
                        <p>P.O. Box 101,</p>
                        <p>Lilongwe.</p>
                        <p>Tel: 01 756 042</p>
                        </p>
                  </div>
                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>     
                    <p className='p-3 text-bold'> DRTSS Regional Office North,
                        <p>P.O Box 115,</p> 
                        Mzuzu.
                      <p>Tel: 01 312 323 /<p>099 500 84 59</p></p>
                    </p>
                  </div>
                  
                  
                  
        </div>
        </Modal.Body>
        <div className="max-w-[880px] w-full  mx-auto text-center  justify-center p-2">
        <h1 className="md:text-3xl sm:-3xl text-2xl font-bold p-4 text-[#1e0e66]  border-indigo-200">
            TOLL FREE LINE
          </h1>
          <p className='text-6xl bg-[#1e0e66] text-white p-2 text-center w-[200px] justify-center mx-auto'>4040</p>
            <p className='text-[#1e0e66] hover:bg-white w-fit mx-auto transition duration-100 ease-in-out text-2xl rounded-md italic p-2'> for Airtel Subscribers only</p>
        </div>
      </Modal>
  <div className='relative bg-gradient-to-tl from-blue-800 to-yellow-600'>
    <img  className='absolute object-cover w-full h-full mix-blend-overlay ' alt='' src={Background}/>
    <div className='max-w-[880px] w-full h-screen mx-auto text-center flex flex-col justify-center relative '>
      <h1 className='md:text-3xl sm:-3xl text-2xl font-bold md:py-6 text-white'> Directorate for Road Traffic And Safety Services </h1>
      <div className='p-2  justify-center flex space-x-4 '>
      <button className='bg-[#131313] p-3  text-2xl  text-white hover:bg-[#1e0e66] transition ease-in' onClick={handleShow}>Contact Us</button>
    </div>
      </div>
  </div> 
  </>
    
  
    
  )
}

export default HeroSection