import React from 'react'

function footer() {


  return (
    <>
    <div className='max-w-full mx-aut p-8 grid grid-col-2 gap-3 text-[#1e0e66] font-medium bg-white align-center'>
        <div className='lg:col-span-2 gap-8 flex justify-start text-sm'>
          <div> 
            <h6 className='font-bold text-[#1e0e66]'> Useful Links</h6>
          <ul>
          <li className='py-2 text-sm'>
               <a href="https://www.rfamw.com" target="_blank" rel="noopener noreferrer"> DRTSS YouTube Page</a></li>

            <li className='py-2 text-sm'>
             <a href="http://www.malawi.gov.mw/" target="_blank" rel="noopener noreferrer">Malawi Government</a></li>
            <li className='py-2 text-sm'>
            <a href="http://www.ra.org.mw" target="_blank" rel="noopener noreferrer">National Roads Authority</a></li>
           
            <li className='py-2 text-sm'>
               <a href="https://www.rfamw.com" target="_blank" rel="noopener noreferrer">Roads Fund Administration</a></li>
               
          </ul>
          </div>
          <div> 
        
          <ul className='mt-3'>
            <li className='py-2 text-sm'>
              <a href="http://www.malawi.gov.mw" target="_blank" rel="noopener noreferrer">e-Government</a></li>
            
            <li className='py-2 text-sm'>
              <a href="https://mra.mw" target="_blank" rel="noopener noreferrer">Malawi Revenue Authority</a></li>
              <li className='py-2 text-sm'>
              <a href="https://www.devex.com/organizations/ministry-of-transport-and-public-works-malawi-110544" target="_blank" rel="noopener noreferrer">Ministry of Transport and Public Works</a></li>
            <li className='py-2 text-sm'>
             <a href="https://apcof.org/country-data/malawi/" target="_blank" rel="noopener noreferrer">National Police Services</a></li>
          </ul>
          </div> 
        </div>
    </div>
    <div className='text-black text-sm  w-100% p-4 bg-white '>
      <h4 >2023 Designed and Developed by Directorate of Road Traffic and Safety Services. All Rights Reserved.</h4>
</div>
                </>
  )
}

export default footer