import React from 'react'

function international_driving_permit_application() {
  return (
    <div>
      <h1 className="p-4 font-semibold text-2xl">International Driving Permit</h1>
  
  <div className="inline-block p-4">
  <p>
  A person can apply for an International Driving Permit (IDP) to be authorized to drive in a foreign country.
    </p>
  <h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>Remember that you have to be enrolled on MalTIS before applying to schedule an IDP. For instructions on how to enroll please click here: NATURAL PERSONS
</h4>
  </div>

  <div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
  <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-2 text-sm m-4'>CHECKLIST FOR INTERNATIONAL DRIVING PERMIT. DO YOU HAVE?</h4>
          <li>Traffic Register Card - received when you enroll on MalTIS. </li>
          <li>A valid Malawian driving license.
            <li>
            Note -- to apply for an IDP you must have been in possession of a Malawian Driving Licence for a period longer than 1 (ONE) year.
           </li>
        </li>
          <li>Completed INTERNATIONAL DRIVING PERMIT APPLICATION form.</li>
         
          <p className='bg-blue-800 w-100% text-white p-2 m-4 w-fit'>
          APPLICATION PROCESS
        </p>

          <li>
          To avoid filling in forms in the queue, download and print the INTERNATIONAL DRIVING PERMIT APPLICATION form and medical certificate application form (if applicable) now and complete it before you arrive at the DRTSS office.
          </li>
          <li>
          Visit your nearest DRTSS OFFICE.

          </li>
          <li>
          Submit your application at the Person Applications counter.
          </li>
          <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.</li>
          <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
          <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip to collect your IDP.</li>
          <li>Go to the Document Issuing office to collect your IDP.</li>
          
    </div>
    </div>
  )
}

export default international_driving_permit_application
