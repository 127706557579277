import React from 'react';
import Footer from '../Components/footer';
function About() {
  return (
    <>

      <div className="items-center min-h-screen text-center m-4 p-3  text-white">
        <div className="inline-block mx-20">
          <h1 className="text-4xl font-bold m-10">DIRECTORATE OF ROAD TRAFFIC AND SAFETY SERVICES (DRTSS) </h1>
          <p className="text-justify">
           Is a Department under the Ministry of Transport and Public Works (MoTPW).
          It is charged with the responsibility of administering regulatory provisions governing the road transport industry in Malawi. 
          The operations of the Directorate are guided by the Road Traffic Act (1997) and Road Traffic Regulations (2000).
          The new look DRTSS is a result of the merger between the Road Traffic Directorate (RTD) and the National Road Safety Council of Malawi (NRSCM) in 2010.
          These are institutions which were established under different legal instruments (the Road Traffic Act, 1997 and the National Road Safety Council Act, 1978 respectively) but were performing complimentary functions.
          Their merger which has been one of the key government reform areas in the transport sector was meant to improve efficiency and effectiveness of the services rendered by removing the overlaps and duplication of functions.
          In the current setup, the Directorate plays duo roles, first as a regulator that enforces laws and regulations including the provision of advisory services to government institutions and other stakeholders in the road transport sector.
          Secondly it is also responsible for implementing the various policies and regulations pertaining to the operations of the road transport players. 
          </p>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default About;
