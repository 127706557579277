import './App.css';
import Nav from '../src/Components/Nav'
import HeroSection from './Components/HeroSection';
import AnalyticsSection from './Components/AnalyticsSection';
import Footer from './Components/footer'
import {Outlet} from 'react-router-dom';
import {ProSidebarProvider} from 'react-pro-sidebar'

function App() {
  
  return (
    <ProSidebarProvider>
    <Nav/> 
    <Outlet/>
    <HeroSection/>
    <AnalyticsSection/>
    <Footer/>
    </ProSidebarProvider>
  );
}

export default App;
