import React from 'react'


function AnalyticsSection() {
  return (
    <div className='w-full bg-white py-6 px-4'>
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-3'>
      <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 bg-[#1e0e66] text-white'>
                  <div className='p-2 bg-white text-center text-lg font-[900] text-[#1e0e66] rounded-md'>MANDATE</div>     
                    <p className='p-3 text-bold text-justify'> To regulate the road transport industry through law enforcement,
                       development of policies, standards and practices;
                        and provide civic education to the citizenry in order to ensure
                         a safe and sustainable road transport system.</p>
                  </div>

                  <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 bg-[#1e0e66] text-white'> 
        
        <div className='p-2 bg-white text-center text-lg font-[900] text-[#1e0e66] rounded-md'> VISION</div>    
                    <p className='p-3 text-bold text-justify'> A safe and sustainable road transport system.</p>
                  </div>

      <div className='w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 bg-[#1e0e66] text-white'>
                  <div className='p-2 bg-white text-center text-lg font-[900] text-[#1e0e66] rounded-md'>MISSION</div>     
                    <p className='p-3 text-bold text-justify'>To administer regulatory provisions governing motor vehicle administration,
                     driver licensing administration,
                      operator authorisation and permit control and issues related totraffic management and control.</p>
                  </div>
        
                  
                  
                
        </div>    
    </div>
  )
}

export default AnalyticsSection