import React from 'react'
import {Link} from 'react-router-dom'
function AboutSideBar() {
  return (
    <div className='fixed  left-0 top-[50px]  bottom-0 bg-white border-r-4 border-indigo-100 w-[20%] h-100%  p-[20px] overflow-auto text-[#1e0e66]'>     
     <div>
        <ul className="text-sm -[100]%   ">
          <li className='p-2 hover:bg-slate-200 transition ease-out rounded-md text-[#1e0e66]'>
          <Link
            className=" font-bold p-1 cusror-pointer"
            to="/about/MVM">
            Mandate,Vision and Mission
          </Link>
          </li>
            <li className='p-2 hover:bg-slate-200 transition ease-out rounded-md text-[#1e0e66]'>
            <Link
              className=" font-bold p-1  cusror-pointer"
              to="/about/Corevalues"> Core Values
             </Link>
            </li>
        {/* <li className='p-2 hover:bg-slate-200 transition ease-out rounded-md text-slate-700'>
            <Link
              className=" font-bold p-1 text-black cusror-pointer"
              to="/about/Divisions">
              Drtss Publications
            </Link>
            </li> */}
            
            <li className='p-2 hover:bg-slate-200 transition ease-out rounded-md text-[#1e0e66]'>
          
              <a href='https://drive.google.com/file/d/1yalXzllmDhEoXAeOAj3BxdzqRWToyYtH/view?usp=share_link' className='font-bold'>Governance Structure & Divisions</a>
            
            </li>
        </ul>
     </div>
    </div>
  )
}

export default AboutSideBar
