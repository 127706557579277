import React from 'react'

function Certificate_of_fitness() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Certificate of Fitness</h1>

<div className="inline-block p-4">
<p>
Any vehicle operated on a public road must have a valid Certificate of Fitness (COF).
  </p>
  
<h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Note: You have to be enroled on MalTIS before applying for a COF. For instructions on how to enrol please click here: NATURAL PERSONS / BODY OF PERSONS
Note: Only the Owner of the vehicle or proxy of the owner may apply for a COF.
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-2 text-sm m-4'>COF RENEWAL RULES:</h4>
        <li>The following types of vehicles renew every 6 months:
        <li className='pl-5'>Motor vehicles conveying passengers for reward ie. Usage = Passengers for reward / Taxi / Hire Car, and</li>
        <li className='pl-5'>Motor vehicle of a driving school used for driving instruction purposes ie. Usage = Driving School.</li>            
        </li>
        <li>All other vehicles renew every 12 months ie. Usage = Private / Goods.</li>
       
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        CHECKLIST FOR COF. DO YOU HAVE?
      </p>

        <li>
        Traffic Register Card/Certificate – of the owner.
        </li>
        <li>
        Completed COF APPLICATION form.
        </li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        APPLICATION PROCESS FOR COF
      </p>
        <li>
        Go to the Vehicle Application Office.
        </li>
        <li>Submit your application at the counter.</li>
        <li>If all is in order the vehicle will be registered to a title holder and owner and a standard vehicle registration number will be allocated. If you require a Special, Vanity, Out-of-Sequence or Next-in-Sequence Registration Number please click here: SPECIAL / VANITY REGISTRATION NUMBER APPLICATION.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
          <li  className='pl-5'>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
        <li>Go to the Document Issuing Office. The title holder will receive the vehicle registration certificate (Blue Book) and the owner will receive the authorization to purchase registration plates.</li>
        
  </div>  
  </div>
  )
}

export default Certificate_of_fitness
