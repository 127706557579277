import React from 'react';

function vehicleapplications() {
  return (
    <>
      <h1 className="p-4">Vehicle application</h1>
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
      <br />
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-4 p-4">
        <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <h1>TRAFFIC MANAGEMENT</h1>
          <p className="p-3 text-bold">
            {' '}
            The Diractorate is charged with administering, regulatory,
            provisions relating to motor vehicle administration, driver
            licensing administration, operator authorisation and control of
            permits.
          </p>
        </div>
        <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <h1>SAFETY</h1>
          <p className="p-3 text-bold">
            {' '}
            The diractorate of road Traffic remain the enforcing body for
            matters linked to safety and permit requirements and also the
            jointly regulated allocation of minibus routes with MOAM
          </p>
        </div>
        <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <h1>WEIGHBRIDGE</h1>
          <p className="p-3 text-bold">
            {' '}
            In an effort to improve the effectiveness of overloading control the
            Directorate has strengthen the overall management and monitoring of
            axle load control operations through vehicle load control.
          </p>
        </div>
      </div>
    </>
  );
}

export default vehicleapplications;
