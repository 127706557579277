import React from 'react'

function downloadIndex() {
  return (
     <div className="inline-block ml-[20%] w-4/5 bg-[#1e0e66] p-4">
    <div>

      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
          <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?id=1xDAI1rUP6vu9V4A7zmxWeuKRZOJFTm4w&export=download'>Application for Certificate of Fitness (COF)</a>
          </div>
      
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
             
              <a href='https://drive.google.com/uc?export=download&id=1ogNtI_F8BWnFt7qBikO2-cXIccj555vw'> Application for Learner Licence or Driving Licence or International Driving Permit or Proffessional Driving Permit</a>
          </div>
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?export=download&id=1_sZf5KE0ZJLYVKFj2pE4MtSd2K244fHc'>Application for Vehicle Registration</a>
              
          </div>
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
              
             <a href='https://drive.google.com/uc?export=download&id=1mSgBIB1O2X2CSAL0CAH3GzXHBsJkt_5Y'>Application for Special or Vanity Registration Number</a>
          </div>

          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
             <a href="https://drive.google.com/uc?export=download&id=1oDEIGMDmDpd908rOQdkhlogNuhMxysXn"> Application for Entity Enrollment</a>
              
          </div>
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?export=download&id=1V8yjlw60nHq1UVwmZ-NxL0z2Xq3z8TuG'>Application for Infrastructure Registration</a>
              
          </div>
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>

              <a href='https://drive.google.com/uc?export=download&id=1-FWqizHkhYmLRceoO1i5kaj8QE3-Fsaf'>Medical Certificate</a>
             
          </div>
      
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
             <a href='https://drive.google.com/uc?export=download&id=1H7HSIXw6P28TY92TCWQCceB5rr1ILBEI'>Notification of Sale of Vehicle</a>
           
          </div>
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?export=download&id=183iVhiedDLr4fXgZr2BPxsfDDE6wKkkv'>Application for Operator Permit</a>
              
          </div>
      </div>
  </div>
  </div>

  )
}

export default downloadIndex
