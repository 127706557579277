import React from 'react'
import Nav from '../Components/Nav'
import Pictures from '../Photos.json'
import Enforcements from '../ElectronicEnforcement.json'
import {Tabs,Tab} from 'react-bootstrap';
import Infrastructures  from '../Infrastructure.json';
import Commemorations from '../AfricanDay.json';
import Licenses from '../License.json';
import Committees from '../ICC.json'

function media() {
  return (
    <>
      <Nav/>
      <div className="justify-center p-4">
      <Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      
    >
      <Tab  variant="pills" eventKey="home" title=" Awareness Activities">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-3 p-2'>
      {
         Pictures && Pictures.map(picture =>{
          return(
            <div className='w-full  flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-500' key={picture.id}>
                <img className='w-[100%]' alt={picture.image} src={picture.image}/>
                <p className='text-white'>{picture.description}</p>
               
            </div>
            
          )
        })
      }
      </div>
      </Tab>
      <Tab eventKey="Enforcement" title="Enforcement Activities">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-3 p-2'>
      {
         Enforcements && Enforcements.map(Enforcement =>{
          return(
            <div className='w-full  flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-500' key={Enforcement.id}>
                <img alt={Enforcement.image} src={Enforcement.image}/>
                <p>{Enforcement.description}</p>
            </div>
          )
        })
      }
      
      </div>
      </Tab>
      <Tab eventKey="Infratsructure" title="Infratsructure Protection">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-3 p-2'>
      {
         Infrastructures && Infrastructures.map(Infrastructure =>{
          return(
            <div className='w-full  flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-500' key={Infrastructure.id}>
                <img alt={Infrastructure.image} src={Infrastructure.image}/>
                <p>{Infrastructure.description}</p>
            </div>
          )
        })
      }
      
      </div>
      </Tab>

      {/* <Tab eventKey="Licenses" title="Licensing Activities">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-3 p-2'>
      {
         Licenses && Licenses.map(License =>{
          return(
            <div className='w-full  flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-500' key={License.id}>
                <img alt={License.image} src={License.image}/>
                <p>{License.description}</p>
            </div>
          )
        })
      }
      
      </div>
      </Tab> */}

      <Tab eventKey="Africa" title="Africa Road Safety Day">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-3 p-2'>
      {
         Commemorations && Commemorations.map(Commemoration =>{
          return(
            <div className='w-full  flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-500' key={Commemoration.id}>
                <img alt={Commemoration.image} src={Commemoration.image}/>
                <p>{Commemoration.description}</p>
            </div>
          )
        })
      }
      
      </div>
      </Tab>

      <Tab eventKey="ICC" title="IIC">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-3 p-2'>
      {
         Committees && Committees.map(Committee =>{
          return(
            <div className='w-full  flex flex-col p-4 my-2 rounded-lg hover:scale-105 duration-500' key={Committee.id}>
                <img alt={Committee.image} src={Committee.image}/>
                <p>{Committee.description}</p>
            </div>
          )
        })
      }
      
      </div>
      </Tab>


      <Tab eventKey="YouTube" title="Youtube Channel">
      <div className='justify-center mx-auto flex p-4'>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/b0XhqcusAdE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> 
       </div>
      </Tab>
    </Tabs>
      </div>
      

 
      
    </>
  )
} 


export default media
