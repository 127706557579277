import React from 'react';

export default function drivingpermits() {
  return (
    <>
      <h1 className="p-4">driving permits</h1>
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
      <br />
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
      <div className="inline-block p-4">
        {' '}
        Mobile First means designing for mobile before designing for desktop or
        any other device (This will make the page display faster on smaller
        devices). This means that we must make some changes in our CSS. Instead
        of changing styles when the width gets smaller than 768px, we should
        change the design when the width gets larger than 768px. This will make
        our design Mobile First:
      </div>
    </>
  );
}
