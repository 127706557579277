import React from 'react'

function Embosser() {
  return (
    <div>
      <div className="inline-block ml-[20%] w-4/5 bg-[#1e0e66] p-4">
    <div>
    
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8'>



          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?export=download&id=1gamJNWgwtxs6PABD6KiLDcwh2jht-J7K'>Emboser of Number Plates Application Form</a>
              
          </div>
          <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?export=download&id=1V3B4OQ_kh3YWW7t5bppxSjQ0VZ04ZJkr'> Importer of Number Plates Application Form</a>
              
          </div>
      </div>
  </div>
  </div>
    </div>
  )
}

export default Embosser
