import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/footer';
import DownloadSidebar from './DownloadSidebar';
import { Outlet } from 'react-router';
import Regulations from './regulations';
function downloadPage() {
  return (
    <>
  <Nav/>
  <DownloadSidebar/>
  
  <Outlet/>
    <div className="inline-block ml-[20%] w-4/5 bg-[#1e0e66]">
  <Footer/>
    </div>
    </>
  )
}

export default downloadPage
