import React from 'react'

function publications() {
  return (
    <div className='inline-block ml-[20%] w-4/5 bg-[#1e0e66]'>
  <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 p-4'>
<div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
  <a href='https://drive.google.com/uc?export=download&id=1Kr754-SFyAPOpE6Q1CSVjIUXRZgVPygM'>Basic Road Safety Handbook</a>
</div>
<div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
  <a href='https://drive.google.com/uc?export=download&id=166dl3IXWI5Kagat2U7sNS3kX6Yat5f6n'>Client Service Charter</a>
</div>
<div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
  <a href='https://drive.google.com/uc?export=download&id=1RVx9O7NIcv8L0QmjAt5TpoIjum90qRjj'>Corruption and Fraud Prevention Policy</a>
</div>
<div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
  <a href='https://drive.google.com/uc?export=download&id=1uis_ofpdMfzpMAm4Pq45H7iwA6HrdmDm'>Infant Road Safety Manual</a>
</div>
<div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
  <a href='https://drive.google.com/uc?export=download&id=1bvd5GUNPuWQQh_bHIk-18JPllPuSoNYQ'>Road Safety Pack for Competency Based Training of Motorcycle Riders</a>
</div>
</div>
   
    </div>
  )
}

export default publications
