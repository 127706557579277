import React from 'react';
function infrastructure() {
  return (
    <>
      <p> welcome to the infrastructure page</p>
    </>
  );
}

export default infrastructure;
