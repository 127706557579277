import React from 'react'

function PressRelease() {
  return (
     <>
     <div className="inline-block ml-[20%] w-4/5 bg-[#1e0e66] p-4">
    <div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
          <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?export=download&id=1ROXqiH_G2DEvp8VtOyzsl3crLYeqyARw'>Re-Introduction of Vehicle Log Books for Public Service Vehcile</a>
          </div>
      
          <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
              <a href='https://drive.google.com/uc?export=download&id=1HZgEw3csnPU8vz_5-QJps65xIzWAYvgc'>Operation of Taxis</a>
          </div>
          
      </div>
  </div>
  </div>
     </>
  )
}

export default PressRelease
