import React from 'react'

function notification_of_sale() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Notification of Sale</h1>

<div className="inline-block p-4">
<p>
A notification of sale must be performed at a DRTSS before a Title Holder Conveyance or Change of Ownership of a vehicle can occur.
  </p>
  
<h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Remember that you have to be enrolled on MalTIS before applying for a notification of sale. For instructions on how to enroll please click here: NATURAL PERSONS / BODY OF PERSONS
Only the title holder of the vehicle (or his/her proxy) is able to perform the notification of sale transaction.
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-2 text-sm m-4'>CHECKLIST FOR NOTIFICATION OF SALE. DO YOU HAVE?</h4>
        <li>Traffic register Card/Certificate of the title holder (or proxy) of the vehicle.</li>
        <li>Vehicle identifier ie. Registration number, VIN or Register number</li>
        <li>Completed NOTIFICATION OF SALE APPLICATION form.</li>            
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        APPLICATION PROCESS
      </p>

        <li>
        To avoid filling in forms in the queue, download and print the NOTIFICATION OF SALE APPLICATION form now and complete it before you arrive at the DRTSS office.
        </li>
        <li>
        Visit your nearest DRTSS OFFICE.
        </li>
        <li>
        Go to the Vehicle Application office.
        </li>
        <li>Submit your application at the counter.</li>
        <li>The transaction will be processed. The submission of a Notification of Sale does not require payment.</li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        NOTE – A submission of a Notification of Sale is not a Title Holder Conveyance or Change of Ownership transaction. It is merely a notification that the vehicle was sold and that a new Title Holder and Owner may be identified.
      </p>
  </div>  
  </div>
  )
}

export default notification_of_sale
