import React from 'react';

function minor() {
  return (
    <>
      <div className=" inline-block ml-[10%] p-4">
      <h1 className='text-2xl p-2  font-medium'>Minors (Person below 16 years)</h1>
      <p>Before any transaction can be initiated on MalTIS a person or organisation/company must be enroled. To register a minor (younger than 16 years) follow the instructions below. If you are over the age of 16 or want to register an organisation/company please follow one of these links: NATURAL PERSONS / BODY OF PERSONS.</p>
      <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-1 text-sm mt-2'>CHECKLIST FOR ENROLMENT AS A MINOR. DO YOU HAVE?</h4>
      <p> <ul className='space-y-2 mt-2'>
              <li>•	Public/Private Companies</li> 
              <li>•	Sole Proprietors</li>
              <li>•	Partnerships</li>
              <li>•	Statutory Companies</li>
              <li>•	Government Department and Armed Forces</li>
              <li>•	Non-Profit Organisations</li>
              <li>•	Non-Government Organisations</li>
              <li>•	Universities, Schools and Colleges</li>
              <li>•	Sports Clubs</li>
              <li>•	Churches</li>
              <li>•	Political Organisations</li>
        </ul>
      </p>
        <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-1 text-sm mt-2'>CHECKLIST FOR ENROLMENT AS A BODY OF PERSONS. DO YOU HAVE?</h4>
        
        <div className='bg-slate-100/50 mt-4 w-fit p-2 rounded-md'>
            <p className='bg-blue-800 w-100% text-white p-2  m-4'>
              A document indicating the body of person's previous <span className='font-bold'>TRN number:</span>
            </p>
              <li>Vehicle registration certificate </li>
              <li> Certificate of Fitness</li>
              <li>Operator registration certificate</li>
              <li>Operator permit or Infrastructure registration certificate</li>
             
              <p className='bg-blue-800 w-100% text-white p-2 m-4'>
              Proof of identification: 
            </p>

            <li>Vehicle registration certificate <span className='text-blue-400 font-bold'>(blue book)</span> where you are the Title Holder and/or Owner of the vehicle </li>
              <li>Certificate of Fitness disc where you are the Title Holder / Owner of the vehicle</li>
              <li>Operator registration certificate</li>
              <li>Operator permit</li>
              <li>Infrastructure registration certificate</li>
              <li>Company Registration Certificate</li>
              <li>Authorization on a letterhead by all partners / directors / shareholders</li>
              <li>Authorization on official government documentation</li>
              <li>Registration certificate issued by the non-governmental organisations board of Malawi</li>
              <li>Registration certificate issued by the registrar of political parties</li>
            <p>Completed APPLICATION FOR ENTITY ENROLMENT form</p>
        </div>
      </div>
    </>
  );
}

export default minor;
