import React from 'react'

function vehicle_Deregistration() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Deregistration of a Vehicle</h1>

<div className="inline-block p-4"> 
<h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Remember: The title holder (or proxy) of the vehicle must be present to provide biometric verification.

</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-2 text-sm m-4'>Vehicles can be deregistered for the following reasons:</h4>
        <li>A vehicle is scrapped;</li>
        <li>A vehicle is exported;</li>
        <li>A vehicle is stolen and not recovered.</li>            
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        CHECKLIST FOR DEREGISTRATION OF A VEHICLE. DO YOU HAVE?
      </p>

        <li>
        Vehicle registration certificate of the vehicle that is going to be deregistered:
        </li>
        <li>
        Traffic Register Card/Certificate of the title holder or proxy.
        </li>
        <li>Completed VEHICLE REGISTRATION APPLICATION form.</li>
        <li>
        The three circumstances for a vehicle to be deregistered have 3 different processes:
        <li>Stolen vehicles require Interpol to mark the vehicle as stolen, where after deregistration may be applied for at the DRTSS.</li>
        <li>Exported vehicles require clearance from both the MRA and Interpol, where after deregistration may be applied for at the DRTSS and an Export Certificate issued for use when crossing the border.</li>
        <li>Scrapped vehicles require an inspection from a registered DRTSS examiner of vehicles, where after the officer will determine if the vehicle can be scrapped.</li>
        </li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        CHECKLIST FOR DUPLICATE CERTIFICATES. DO YOU HAVE?
      </p>
        <li>Traffic register Card/Certificate.</li>
        <li>Vehicle registration number.</li>
        <li>Completed VEHICLE REGISTRATION APPLICATION form.</li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>APPLICATION PROCESS</p>
          <li>To avoid filling in forms in the queue, download and print the VEHICLE REGISTRATION APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
          <li>Visit your nearest DRTSS OFFICE.</li>
          <li>Go to the Vehicle Applications Office.</li>
          <li>Submit your application at the counter.</li>
          <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.</li>

        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        DEREGISTRATION PROCESS
      </p>

      <li>To avoid filling in forms in the queue, download and print the VEHICLE REGISTRATION APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
      <li>Visit your nearest DRTSS OFFICE.</li>
      <li>Go to the Vehicle Applications Office.</li>
      <li>Submit your application at the counter.</li>
      <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
      </li>
      <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
      <li>If the Title Holder is apply for the vehicle to be scrapped:
        <li>Scrapped vehicles require an inspection, where after an inspection officer will determine if the vehicle can be scrapped.</li>
        <li>Exported or Stolen applications do not require an inspection and can proceed to the next step.</li>
      </li>
      <li>Go to the Document Issuing office to collect your deregistration certificate.</li>
  </div>  
  </div>
  )
}

export default vehicle_Deregistration
