import React from 'react'

function change_of_vehicle_particulars() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Change of Vehicle Particulars</h1>
      <p>The Title Holder (or proxy of Title Holder) of a vehicle must register the changes made to the vehicle such as usage, color, seating capacity, engine, etc.</p>
      <div className="inline-block p-4"> 
      <h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Note: You have to be enrolled on MalTIS before applying for a vehicle’s title holder conveyance or change of ownership. For instructions on how to enroll please click here: NATURAL PERSONS / BODY OF PERSONS</h4>
</div>

  <div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
    <h4 className='bg-blue-800 w-fit text-black rounded-md p-2 text-sm m-4'>CHANGES SUBJECT TO A POLICE CLEARANCE CERTIFICATE:
</h4>
        <li>VIN</li>
        <li>Engine Number</li>
        <li>Make</li>         
        <li>Model</li>
        <li>Year of Make</li>
        <li>Colour</li>   
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        A NEW COF IS REQUIRED IF THE FOLLOWING PARTICULARS HAVE CHANGED:
      </p>

        <li>
        Vehicle color
        </li>
        <li>
        Engine number
        </li>
        <li>Vehicle category</li>
        <li>
        Vehicle usage:
        <li>From any 12 month COF validity usage to any 6 month COF validity usage</li>
        <li>ie. Goods to Passengers for reward / Private to Passengers for reward</li>
        </li>
        <li>Tare weight</li>
        <li>Gross Vehicle Mass (GVM)</li>
        <li>Engine Capacity</li>
        <li>Seating Capacity</li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        CHECKLIST FOR CHANGING OF VEHICLE PARTICULARS. DO YOU HAVE?
      </p>
        <li>Traffic Register Card/Certificate of the Title Holder (or proxy) of the vehicle</li>
        <li>Police Clearance Certificate (for reasons mentioned above)</li>
        <li>Vehicle Registration Certificate</li>
        <li>Completed CHANGE OF VEHICLE PARTICULARS APPLICATION form.</li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>APPLICATION PROCESS</p>
          <li>To avoid filling in forms in the queue, download the CHANGE OF VEHICLE PARTICULARS APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
          <li>Visit your nearest DRTSS OFFICE.</li>
          <li>Go to the Vehicle Applications office.</li>
          <li>Submit your application at the counter.</li>
          <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
            <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
          </li>
          <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
          <li>Go to the Document Issuing Office to collect your new vehicle registration certificate.</li>
        
  </div>  
  </div>
  )
}

export default change_of_vehicle_particulars
