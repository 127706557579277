import React from 'react';
import { Link } from 'react-router-dom';
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar'
import {ProSidebarProvider} from 'react-pro-sidebar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';






export default function SideMenu() {

  const FirstTooltip = (post) => (
    <Tooltip id="button-tooltip" {...post}>
      Outsourcing of Vehicle Inspecgtion Function
    </Tooltip>
    
  );
  const SecondToolTip = (post) => (
    <Tooltip id="button-tooltip" {...post}>
      Registration of Operators and Issuance of Permits
    </Tooltip>
    
  );
  return (
    <ProSidebarProvider >
      <Sidebar width={590}>
        <Menu>
      <SubMenu className="text-[#1e0e66]" label="Procedures">
          <SubMenu className="text-[#1e0e66]" label="Entity Enrollment">
            <MenuItem component={<Link to="/procedures/entity-enrollment/natural-persons"/>}> Natural Person</MenuItem>
            <MenuItem component={<Link to="/procedures/entity-enrollment/minor"/>}>Minor</MenuItem>
            <MenuItem component={<Link to="/procedures/entity-enrollment/body-of-person"/>}> Body of persons</MenuItem>
          </SubMenu>


          <SubMenu className="text-[#1e0e66]" label="Driving licenses">
            <SubMenu label="Driving Appplication for New Appicatnats">
            <MenuItem component={<Link to="/procedures/driving-licence/learner-licence-test"/>}> Learner license test</MenuItem>
            <MenuItem component={<Link to="/procedures/driving-licence/driving-licence-test"/>}>Driving license test</MenuItem>
            </SubMenu>
            <MenuItem component={<Link to="/procedures/driving-licence/driving-licence-renewal"/>}> Driving license renewal</MenuItem>
            <MenuItem component={<Link to="/procedures/driving-licence/duplicate-licence"/>}>Driving license Duplciate</MenuItem>
            <MenuItem component={<Link to="/procedures/driving-licence/foreign-licence-conversion-to-malawi"/>}>Conversion of foreign driver's Licence</MenuItem>
          </SubMenu>

          <SubMenu className="text-[#1e0e66]" label="Driving Permits">
            <MenuItem component={<Link to="/procedures/driving-permits/proffessional-driving-application"/>}>Professional Driving Permit Application</MenuItem>
            <MenuItem component={<Link to="/procedures/driving-permits/proffessional-driving-permit-renewal"/>}>Professional Driving Permit renewal</MenuItem>
            <MenuItem component={<Link to="/procedures/driving-permits/proffessional-driving-permit-duplicate"/>}>Professional Driving Permit duplicate</MenuItem>
            <MenuItem component={<Link to="/procedures/driving-permits/international-driving-permit-application"/>}> International driving Permit application</MenuItem>
            
          </SubMenu>

          <SubMenu className="text-[#1e0e66]" label="Infrastructure">
            <MenuItem component={<Link to="/procedures/infrastructure/trade-entity-registration"/>}>Trade Entity</MenuItem>
            <MenuItem component={ <Link to="/procedures/infrastructure/driving-school-registration"/>}>Driving school registration</MenuItem>
            <MenuItem component={ <Link to="/procedures/infrastructure/driving-instructor"/>}>driving Instructor</MenuItem>
          </SubMenu>

          <SubMenu className="text-[#1e0e66]" label="Vehicle Application">
            <MenuItem component={<Link to="/procedures/vehicle-applications/vehicle-registration"/>}>Vehicle Registration</MenuItem>
            <MenuItem component={<Link to="/procedures/vehicle-applications/certificate-of-fitness"/>}>Certificate of Fitness</MenuItem>
            <SubMenu label="Change of Ownership">
            <MenuItem component={<Link to="/procedures/vehicle-applications/notification-of-sale"/>}>Notication of Sale</MenuItem>
            <MenuItem component={<Link to="/procedures/vehicle-applications/change-of-ownership"/>}>Change of Ownership</MenuItem>
            </SubMenu>
            <MenuItem component={<Link to="/procedures/vehicle-applications/change-of-vehicle-particulars"/>}>Change of vehicle Particulars</MenuItem>
            <MenuItem component={<Link to="/procedures/vehicle-applications/public-service-registration-plates"/>}>Public Service Registration Plates</MenuItem>
            <MenuItem component={<Link to="/procedures/vehicle-applications/duplicates-of-certificates"/>}>Duplicate of Certificates</MenuItem>
            <MenuItem component={<Link to="/procedures/vehicle-applications/vehicle-deregistration"/>}> Deregistration Vehicle</MenuItem>
          </SubMenu>
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={SecondToolTip}>
          <SubMenu className="text-[#1e0e66]" label="Registration of Operators and Issuance of Permits">
            <MenuItem component={<Link to="/procedures/operator-permits/operator-registration"/>}>Operator Registration</MenuItem>
            <MenuItem component={<Link to="/procedures/operator-permits/operator-permit-application"/>}>Operator Permit Application</MenuItem>
            <MenuItem component={<Link to="/procedures/operator-permits/renewal-of-operator-permit"/>}>Renewal of  Permit</MenuItem>
            <SubMenu label="Operator Duplicates">
            <MenuItem component={<Link to="/procedures/operator-permits/duplication-of-operator-permit"/>}>Operator Certificate Duplicate</MenuItem>
            <MenuItem component={<Link to="/procedures/operator-permits/duplication-of-operator-permit"/>}>Operator Permit Duplicate  </MenuItem>
            </SubMenu>
            <MenuItem component={<Link to="/procedures/operator-permits/upliftment-of-suspended-permit"/>}>Upliftment Suspended Permit</MenuItem>
          </SubMenu>
          </OverlayTrigger>
            
          </SubMenu>

       
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={FirstTooltip}>
            <SubMenu className="text-[#1e0e66]" label="Outsourcing of Vehicle Inspecgtion Function">
            <MenuItem component={<Link to="/procedures/Vehicle_Inspection/Lilongwe"/>}>Lilongwe</MenuItem>
            <MenuItem component={<Link to="/procedures/Vehicle_Inspection/Blantyre"/>}>Blantyre</MenuItem>
            <MenuItem component={<Link to="/procedures/Vehicle_Inspection/Mzuzu"/>}>Mzuzu</MenuItem>
            
          </SubMenu>
            </OverlayTrigger>
       </Menu>
    </Sidebar>
    </ProSidebarProvider>
  );
}
