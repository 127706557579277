import React from 'react';

import { Link } from 'react-router-dom';
import Icon from '../media/favicon_1.jpg'

function Nav() {

  return (
    <div className="flex h-[50px] items-center mx-w-[720px] w-full p-4 mx-auto  bg-white border-black drop-shadow-md">
      <p className="w-full text-[#1e0e80] font-semibold flex text-4xl flex-1 "><img className=' w-[40px] h-[40px] mr-3' alt='' src={Icon}/>DRTSS</p>
      <ul className="hidden md:flex text-[#1e0e66] font-bold">
        <Link to="/" className="p-4 cursor-pointer">
          Home
        </Link>
        <Link to="/about" className="p-4 cursor-pointer">
          About Us
        </Link>
        <Link to="/service-Center" className="p-4 cursor-pointer">
          Services Centres
        </Link>
        <Link to="/procedures" className="p-4 cursor-pointer">
          {' '}
          Customer Information
        </Link>
        <Link to="/media-center" className="p-4 cursor-pointer">
          Media Centre
        </Link>
        <Link to="/downloads" className="p-4 cursor-pointer">
          Downloads
        </Link>
      </ul>
      {/* <div onClick={handelNav} className="block md:hidden">
        {!nav ? <GiHamburgerMenu size={30} /> : <CgCloseO size={20} />}
      </div>
      <div
        className={
          !nav
            ? 'fixed left-[-100%]'
            : 'fixed left-0 top-0 w-[60%] h-full border-r border-r-zinc-100 bg-[#fff] ease-in-out duration-500'
        }
      >
        <p className="w-full p-2  font-semibold  text-4xl flex text-indigo-800">
          <BiCubeAlt style={{ marginLeft: 10, fontSize: 40 }} />
          DRTSS
        </p>
        <ul className="p-2 uppercase text-indigo-800  font-bold">
          <Link to="/" className="p-4 border-b border-indigo-200">
            Home
          </Link>
          <Link to="/about" className="p-4 border-b border-indigo-200">
            About
          </Link>
          <Link to="service-Center" className="p-4 border-b border-indigo-200">
            Services center
          </Link>
          <Link to="procedures" className="p-4 border-b border-indigo-200">
            Customer infromation
          </Link>
          <Link to="media-center" className="p-4">
            media center
          </Link>
          <Link to="donwloads" className="p-4">
            Downloads
          </Link>
        </ul>
      </div> */}
    </div>
  );
}

export default Nav;
