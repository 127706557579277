import React from 'react'

function driving_school_registration() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Driving School Registration</h1>

<div className="inline-block p-4">
<p>
For a Driving School to be allowed to provide instruction or theoretical tuition to its students, it must be registered on MalTIS. The registration of a school requires the evaluation of the school`s available facilities, the vehicles intended for instruction/tuition and relevant supporting documents.
  </p>
<h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>Remember that you have to be enrolled on MalTIS before registering as a trade entity. For instructions on how to enroll please click here: NATURAL PERSONS or BODY OF PERSONS
Remember All vehicles intended for use in instruction/tuition must have a usage of “Driving School”.
Remember All vehicles intended for use in instruction/tuition must have a valid COF.
Remember The Driving School must be the Title Holder or Owner of two or more vehicles of a Vehicle Category which is relevant to the Grading applied for. See the following table *

</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-2 text-sm m-4'>CHECKLIST FOR INTERNATIONAL DRIVING PERMIT. DO YOU HAVE?</h4>
        <li>Traffic Register Card/Certificate of Entity (Natural Person or Body of Persons) or their Proxy.</li>
        <li>Supporting Documentation:
          <li>
          Police report
         </li>
         <li>
         MRA VAT Returns
         </li>
         <li>
         Insurance
         </li>
         <li>
         ODPP Registration Certificate
         </li>
      </li>
        <li>Completed INFRASTRUCTURE REGISTRATION form.</li>
       
        <p className='bg-blue-800 w-100% text-whitep-2 m-4 w-fit'>
        DRIVING SCHOOL REGISTRATION PROCESS
      </p>

        <li>
        To avoid filling in forms in the queue, download and print the INFRASTRUCTURE REGISTRATION form now and complete it before you arrive at the DRTSS office.
        </li>
        <li>
        Visit your nearest DRTSS OFFICE.

        </li>
        <li>
        Submit the registration form and supporting documentation.
        </li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.</li>
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        <li>Once payment is made, a DRTSS Officer will schedule an inspection of your premises.</li>
        <li>Upon completion of the inspection, the DRTSS Officer will indicate to you whether or not you have passed. If the inspection was passed, you may return to the DRTSS Office.</li>
        <li>Go to the Driving Examiner's Office.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.</li>
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
        <li>Go to the Document Issuing office to collect your Driving School Certificate.</li>
        <li>Your registration, unless canceled or suspended, will be valid for 12 months. Please renew your registration before the expiry date at a reduced fee.</li>
        
  </div>
  </div>
  )
}

export default driving_school_registration
