import React from 'react'
import Datasets from './Lilongwe.json';
import Table from 'react-bootstrap/Table'
function Lilongwe() {
  return (
    <div className='inline-block ml-[10%] p-4 w-4/5 text-white'>
      {Datasets && Datasets.map((data, index) =>{
        return(
          <div>
            <div className='p-4 text-md justify-start text-xl text-justify'>{data.description}</div>
<Table striped bordered>
      <thead>
        <tr className='text-white'>
          <th className='text-white'>Name Of Vehicle Inspection Station</th>
          <th className='text-white'>Class</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='text-white'>{data.id.description1}</td>
          <td className='text-white text-justify'>{data.Class.id.id1}</td>
          
        </tr>
        <tr>
          <td className='text-white'>{data.id.description2}</td>
          <td className='text-white text-justify'>{data.Class.id.id2}</td>
        </tr>
        <tr>
          <td className='text-white'>{data.id.description3}</td>
          <td className='text-white text-justify'>{data.Class.id.id3}</td>
        </tr>
        <tr>
          <td className='text-white'>{data.id.description4}</td>
          <td className='text-white text-justify'>{data.Class.id.id4}</td>
        </tr>
        <tr>
          <td className='text-white'>{data.id.description5}</td>
          <td className='text-white text-justify'>{data.Class.id.id5}</td>
        </tr>
      </tbody>
    </Table>

          </div>
        )
      })}
      
    </div>
  )
}

export default Lilongwe
