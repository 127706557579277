import React from 'react'
import { Link } from 'react-router-dom'
import {ProSidebarProvider} from 'react-pro-sidebar'
import {Sidebar, Menu, MenuItem,} from 'react-pro-sidebar'
function DownloadSidebar() {
  return (
<>
<ProSidebarProvider>
    <div className='fixed  left-0 top-[50px] overflow-hidden  bottom-0 bg-white border-r-4 border-indigo-100 w-[20%] h-100%  p-[20px]'>     
    <Sidebar>
      <Menu>
        <MenuItem component={<Link to="RoadTraffic"/>}>Road Traffic Act & Regulations</MenuItem>
        <MenuItem component={<Link to="DownloadIndex"/>}>MalTIS Transactions Forms</MenuItem>
        <MenuItem component={<Link to="/downloads/publications"/>}>DRTSS Publications</MenuItem>
        <MenuItem component={<Link to="Embosser"/>}>Embosser & Importer of Number Plates Application Forms</MenuItem>
        <MenuItem component={<Link to="Pressrelease"/>}>Press Releases and Announcements </MenuItem>
      </Menu>
    </Sidebar>
         </div>
    </ProSidebarProvider>
                </>
   
  )
}

export default DownloadSidebar
