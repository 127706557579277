import React from 'react';
import Background from '../media/BT DOCUMENT ISSUING (4).JPG'
function ProceduresIndex() {
  return (
    <div className='bg-[#1e0e66] text-white ' >
        <img className='w-full h-full' alt="" src={Background}/>
    </div>
  );
}

export default ProceduresIndex;
