import React from 'react'

function duplicate_licence() {
  return (
    <div>
      
    <h1 className="p-4 font-semibold text-2xl">Applying for a Duplicate Licence (Driving Licence or PrDP)</h1>
  
    <div className="inline-block p-4">
    If your licence is destroyed, lost or stolen you may apply for a duplicate. Please note that a duplicate licence will not be issued without a valid police report or if the driving licence has been suspended.
    <h4 className='bg-blue-800 w-100% text-white w-100% rounded-md p-1 text-sm mt-2'>
    Remember that you have to be enroled on MalTIS before applying for a Driving licence duplicate. For instructions on how to enrol please click here: NATURAL PERSONS</h4>
    </div>

    <div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
    <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-2 text-sm m-4'>CHECKLIST FOR SCHEDULING LEARNER LICENCE TEST. DO YOU HAVE?</h4>
            <li>Traffic Register Card - received when you enrol on MalTIS</li>
            <li>Completed DRIVING LICENCE OR PRDP DUPLICATE APPLICATION form.</li>
            
            <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
            APPLICATION PROCESS
          </p>

            <li>To avoid filling in forms in the queue, download the DRIVING LICENCE OR PRDP DUPLICATE APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
            <li>Visit your nearest DRTSS OFFICE.</li>
            <li>
            	Submit your application at the Person Applications counter.</li>
              <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
              <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment at NBS Bank.</li>
              </li>
                <li>5.	Once payment is made, you may return to the DRTSS office with the stamped deposit slip, to collect your duplicate Learner Licence, Driving Licence card or PrDP card from the Document Issuing Office.</li>
            <h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>NOTE – Once the duplicate licence has been printed, the original licence will be cancelled and become invalid. The original validity period will remain unchanged for the duplicate licence.</h4>
      </div>
    
  </div>
  )
}

export default duplicate_licence
