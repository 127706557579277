import React from 'react'

function driving_intructor() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Driving Instructors New Registration
</h1>

<div className="inline-block p-4">
<p>
Before a Driving Instructor is allowed to provide instruction or theoretical tuition to students he/she must be registered on MalTIS.
  </p>
<h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>Remember that you have to be enrolled on MalTIS before registering as a driving Instructor. For instructions on how to enroll please click here: NATURAL PERSONS or BODY OF PERSONS
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-2 text-sm m-4'>DRIVING INSTRUCTOR APPLICANT MUST:</h4>
        <li>Have continuously held a valid Malawi Driving Licence in respect of the class or classes to which the applied for Instructor`s Licence relates for a period of 3 years. Refer to the INFRASTRUCTURE REGISTRATION form;</li>
        <li>
        Have completed an approved driving instructor training program and passed the examination.
        </li>
        <li>
        Be between the age of 18 and 65 years of age.
        </li>
        <li>
        A Driving Instructor may NOT be employed at more than 1 Driving School.
        </li>
        <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-2 text-sm m-4'>CHECKLIST FOR DRIVING INSTRUCTOR REGISTRATION. DO YOU HAVE?</h4>
        <li>
        Traffic Register Card of the Entity.
          </li>
        <li>
        Supporting Documentation:
        <li>Medical Certificate of Fitness.</li>
        <li>Certificate of highest level of education.</li>
        <li>Driving Instructor Training Program Certificate.</li>
        <li>Driving Licence Examination Training Program Certificate.</li>
        <li>Police Report.</li>
          </li>

        <li>A Driving Instructor may NOT be employed at more than one (1) Driving School.</li>
        <li>Completed INFRASTRUCTURE REGISTRATION form.</li>

        <h4 className=' w-fit  rounded-md p-1 text-sm mt-2'>DRIVING INSTRUCTOR REGISTRATION PROCESS</h4>

        <li>To avoid filling in forms in the queue, download and print the INFRASTRUCTURE REGISTRATION form now and complete it before you arrive at the DRTSS office.</li>
        <li>
        Visit your nearest DRTSS OFFICE.
        </li>
        <li>
        Go to the Driving Examiner’s Office.
        </li>
        <li>
        Submit the registration form and the supporting documentation.
        </li>
        <li>Submit the registration form, the proof of identification and the supporting documents listed above in the checklist.
</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>

        <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-1 text-sm mt-2'>IMPORTER FOR OWN USE REGISTRATION PROCESS</h4>

        <li>Go to the Document Issuing office to collect your Driving Instructor card.</li>
        <li>
        A user name will be issued to you to allow you to capture lessons.

        </li>
        <li>
        Your registration, unless canceled or suspended, will be valid for 12 months. Please renew your registration before the expiry date at a reduced fee.

        </li>
       
  </div>  
  </div>
  )
}

export default driving_intructor
