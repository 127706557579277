import React from 'react'

function duplication_of_operator_permit() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Duplication of Operator Certificate or Permit</h1>

<div className="inline-block p-4">
<p>
A duplicate of an operator certificate or permit can be issued if the original document was lost, stolen, damaged or defaced. Please note that duplicates will not be issued if a valid police report does not accompany the application, nor if the operator is presently suspended or has any outstanding contraventions.
  </p>
<h4 className='bg-blue-800 w-100% text-whitew-fit  rounded-md p-1 text-sm mt-2'>CHECKLIST FOR DUPLICATION OF OPERATOR CERTIFICATE. DO YOU HAVE?
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>

        <li>Traffic Register Card/Certificate of the operator.</li>
        <li>Valid Police Report.</li>
        <li>Completed OPERATOR REGISTRATION form.</li>
        <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-1 text-sm mt-2'>APPLICATION PROCESS
</h4>
<li>To avoid filling in forms in the queue, download and print the OPERATOR REGISTRATION form now and complete it before you arrive at the DRTSS office.</li>
        <li>Visit your nearest DRTSS OFFICE.</li>
        <li>Go to the Vehicle Applications Office or Vehicle Examiner’s Office.</li>
        <li>Submit your application at the counter.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
          <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip and collect your operator certificate.</li>
        <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-1 text-sm mt-2'>NOTE - Once the duplicate certificate has been printed, the certificate being replaced will be canceled and become invalid. The original validity period will remain unchanged for the duplicate certificate.</h4>
  </div>
  </div>
  )
}

export default duplication_of_operator_permit
