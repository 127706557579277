import React from 'react'

function operator_registration() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Operator Registration</h1>

<div className="inline-block p-4">
<p>
Operators must be registered before permits for the transportation of goods or passengers for reward can be issued.
  </p>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-1 text-sm mt-2'>CHECKLIST TO ENROL AS AN OPERATOR. DO YOU HAVE?
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>

        <li>To avoid filling in forms in the queue, download and print the OPERATOR REGISTRATION APPLICATION FORM now and complete it before you arrive at the DRTSS office. </li>
        <li>Visit your nearest DRTSS OFFICE.</li>
        <li>Completed INTERNATIONAL DRIVING PERMIT APPLICATION form.</li>
        <li>Go to the Vehicle Applications Office or Vehicle Examiner’s Office.</li>
        <li>Submit your application at the counter.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
            <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>

        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
        <li>Go to the Document Issuing office to collect your Operator Certificate.</li>
        
        
  </div>
  </div>
  )
}

export default operator_registration
