import React from 'react'

function RoadTraffic() {
  return (
    <>
    <div className="inline-block ml-[20%] w-4/5 bg-[#1e0e66] p-4">
   <div>

     <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
         <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
             <a href='https://drive.google.com/uc?export=download&id=1awH5qhXllSUqfiHD5sk5wADEum5AomvZ'>Road Traffic Act (1997)</a>
         </div>
     
         <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
            
             <a href='https://drive.google.com/uc?export=download&id=1HMZ64XtilOVPi_7l_9SGnymWmt5aD8ft'> Road Traffic Regulations (2000)</a>
         </div>
         <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
             <a href='https://drive.google.com/uc?export=download&id=1u_7ryhUKUAUGtMBzL_i-YaEIbMQHugJM'>Road Traffic (Public Service Vehicles)(Operation) (Amendment) Regulations, (2004)</a>
             
         </div>
         <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
             
            <a href='https://drive.google.com/uc?export=download&id=1VWK7WFjKnl_gKAZlNSEq6VpDJtsjr3J9'>Road Traffic (Prescribed Offences and Penalties)(Amendment) Regulations,(2019)</a>
         </div>

             <div className='w-full bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500  border-b-4 border-blue-400'>
            <a href="https://drive.google.com/uc?export=download&id=1rJCn9RvppPkpyE5lJdrTPVYuPJZ9xK9N">Road Traffic (Miscellaneous fees)(Amendment)  Regulations,(2019)</a>
             
             </div>
         </div>
 </div>
 </div>
    </>
  )
}

export default RoadTraffic
