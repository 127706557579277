import React from 'react';
function MVM() {
  return (
    
      <div className="text-center min-h-screen p-2 mt-4 text-white">
      <div className="items-center min-h-screen text-center m-4 p-3">
        <div className="inline-block mx-20">
          <h1 className="text-4xl font-bold m-10">Mandate</h1>
          <p className="text-justify">
          The mandate of the Directorate is prescribed in the Road Traffic Act (1997) which provides a legal framework for the Road Transport industry.
          Under this Act, the Directorate is charged with the responsibility to administer regulatory provisions governing Motor Vehicle Administration,
          Driver Licensing Administration, Operator Authorization and Permit Control and other issues related to traffic management and safety.</p>
        </div>
        <div className="inline-block mx-20">
          <h1 className="text-4xl font-bold m-10">Vision</h1>
          <p className="text-justify">
          A safe and sustainable road transport system.</p>
          </div>
          <div className="inline-block mx-20">
          <h1 className="text-4xl font-bold m-10">Mission</h1>
          <p className="text-justify">
          To regulate the road transport industry through law enforcement; development of policies, standards and practices;
           and provide civic education in order to ensure a safe and sustainable road transport system</p>
          </div>
          
      </div>
      </div>
    
  );
}

export default MVM;
