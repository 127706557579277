import React from 'react';

function operatorpermits() {
  return (
    <>
      <p> welcome to the operation permits page</p>
    </>
  );
}

export default operatorpermits;
