import React from 'react';

function natural_persons() {
  return (
    <>
     <div className=" inline-block ml-[10%] p-4">
      <h1 className='text-2xl p-2  font-medium'>Natural Persons</h1>
      <p>Before any transaction can be initiated on MalTIS a person or organisation/company must be enroled. To enrol as a Natural Person one must be over the age of 16 years - follow the instructions below. If you are under the age of 16 or want to register an organisation/company, please follow one of these links: MINORS / BODY OF PERSONS.</p>
      <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-1 text-sm mt-2'>
CHECKLIST FOR ENROLMENT AS A NATURAL PERSON. DO YOU HAVE?
</h4>
      <p> <ul className='space-y-2 mt-2'>
              <li>•	Proof of identification. You have to bring along two identity documents. The following identity documents are accepted:
                    <li className='pl-5'>• Citizen Identification Card (National ID) </li>
                    <li className='pl-5'>• Malawian or Foreign Passport</li> 
                </li> 

                <li>•	Completed APPLICATION FOR ENTITY ENROLMENT form</li>
                <li>•	Optional – A proxy is a representative who can perform transactions on your behalf. Your proxy should also be enroled on MalTIS and be present when you enrol.</li>
        </ul>
      </p>
        <h4 className='bg-blue-800 w-100% text-white w-fit rounded-md p-1 text-sm mt-2'>ENROLMENT PROCESS</h4>
        
        <div className='bg-slate-100/50 mt-4 w-fit p-2 rounded-md'>
            
              <li>To avoid filling in forms in the queue, download and print the APPLICATION FOR ENTITY ENROLMENT form now and complete it before you arrive at the DRTSS office.</li>
              <li>Visit your nearest DRTSS OFFICE</li>
              <li>Go to the Biometric Enrolment Office to capture your Photo, Fingerprints and Signature.</li>
              <li>Submit your application at the Person Applications counter / your Identification Documents will be scanned and personal particulars captured.</li>
              <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
              <li className='pl-5'>Please verify that the deposit slip is correct and reflect your details as well as an accurate description of the transaction you wish to complete before making the required payment at NBS Bank.</li>
              </li>
             
             <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip to collect your Traffic Register Card at the Document Issuing Office.</li>

             <li>Keep your Traffic Register Card in a safe place and present it when submitting applications at any DRTSS office.</li>
              
        </div>
      </div>
    </>
  );
}

export default natural_persons;
