import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './About/About';
import Service from './servicesCenter/servicepage';
import Media from './media/media';

import Downloads from './downlaods/downloadPage';
import Publications from './downlaods/publications'
import Regulations from './downlaods/regulations'
import Divisions from './About/Divisions';
import DRTSS from './About/DRTSSProfile';
import Drivinglicense from './procedures/Driving_licence/drivinglicense';
import DrivingLicenceRenewal from './procedures/Driving_licence/driving_licence_renewal';
import DrivingLicenceTest from './procedures/Driving_licence/driving_licence_test';
import DuplicateLicence from './procedures/Driving_licence/duplicate_licence';
import ForeignLicenceConversionToMalawi from './procedures/Driving_licence/foreign_licence_conversion_to_malawi';
import LearnerLicenceTest from './procedures/Driving_licence/learner_licence_test';

import PressRelease from './downlaods/PressRelease';
import VehicleInspection from './procedures/Vehicle_Inspection/Vechicle_Inspection'
import Lilongwe from './procedures/Vehicle_Inspection/Lilongwe';
import Blantyre from './procedures/Vehicle_Inspection/Blantyre';
import Mzuzu from './procedures/Vehicle_Inspection/Mzuzu';

import Entityenrollment from './procedures/Enrollment-Entity/entityenrollment';
import BodyofPerson from './procedures/Enrollment-Entity/Body_of_person';
import Minor from './procedures/Enrollment-Entity/minor';
import NaturalPersons from './procedures/Enrollment-Entity/natural_persons';

import Drivingpermits from './procedures/Driving_permit/drivingpermits';
import InternationalDrivingPermitApplication from './procedures/Driving_permit/international_driving_permit_application';
import ProffessionalDrivingApplication from './procedures/Driving_permit/proffessional_driving_application';
import ProffessionalDrivingPermitDuplicate from './procedures/Driving_permit/proffessional_driving_permit_duplicate';
import ProffessionalDrivingPermitRenewal from './procedures/Driving_permit/proffessional_driving_permit_renewal';

import Operatorpermits from './procedures/Registration_of_operator_licence_of_permits/operatorpermits';
import DuplicationOfOperatorPermit from './procedures/Registration_of_operator_licence_of_permits/duplication_of_operator_permit';
import OperatorPermitApplication from './procedures/Registration_of_operator_licence_of_permits/operator_permit_application';
import OperatorRegistration from './procedures/Registration_of_operator_licence_of_permits/operator_registration';
import RenewalOfOperatorPermit from './procedures/Registration_of_operator_licence_of_permits/renewal_of_operator_permit';
import UpliftmentOfSuspendedPermit from './procedures/Registration_of_operator_licence_of_permits/upliftment_of_suspended_permit';

import DownloadIndex from './downlaods/downloadIndex';

import Vehicleapplications from './procedures/Vehicle_Application/vehicleapplications';
import CertificateOfFitness from './procedures/Vehicle_Application/Certificate_of_fitness';
import ChangeOfOwnership from './procedures/Vehicle_Application/change_of_ownership';
import ChangeOfVehicleParticulars from './procedures/Vehicle_Application/change_of_vehicle_particulars';
import ChangeOfVehicle from './procedures/Vehicle_Application/change_of_vehicle';
import DuplicatesOfCertificates from './procedures/Vehicle_Application/duplicates_of_certificates';
import NotificationOfSale from './procedures/Vehicle_Application/notification_of_sale';
import PublicServiceRegistrationPlates from './procedures/Vehicle_Application/public_service_registration_plates';
import VehicleDeregistration from './procedures/Vehicle_Application/vehicle_Deregistration';
import VehicleRegistration from './procedures/Vehicle_Application/vehicle_registration';

import Infrastructure from './procedures/Infrastructure/infrastructure';
import DrivingInstructor from './procedures/Infrastructure/driving_intructor';
import DrivingSchoolRegistration from './procedures/Infrastructure/driving_school_registration';
import TradeEntityRegistration from './procedures/Infrastructure/trade_entity_registration';

import MVM from './About/MVM';
import CoreValues from './About/CoreValues';
import GovStructure from './About/GovStructure';
import AboutIndex from './About/AboutIndex';
import ProceduresIndex from './procedures/ProceduresIndex';
import Procedures from './procedures/procedures';
import Embosser from './downlaods/Embosser';
import RoadTraffic from './downlaods/RoadTraffic';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />

        {/* these are the routes for the About section */}
        <Route path="/about" element={<About />}>
          <Route index element={<AboutIndex />} />
          <Route path="mvm" element={<MVM />} />
          <Route path="Divisions" element={<Divisions />} />
          <Route path="DRTSS" element={<DRTSS />} />
          <Route path="GovStructure" element={<GovStructure />} />
          <Route path="corevalues" element={<CoreValues/>} />
        </Route>

        {/* these are the routes and subroutes for the proceduers page */}
        <Route path="/procedures" element={<Procedures />}>
          <Route index element={<ProceduresIndex />} />
          {/* driving licence route and subroutes */}
          <Route path="driving-licence">
            <Route index element={<Drivinglicense />} />
            <Route
              path="driving-licence-renewal"
              element={<DrivingLicenceRenewal />}
            />
            <Route
              path="driving-licence-test"
              element={<DrivingLicenceTest />}
            />
            <Route path="duplicate-licence" element={<DuplicateLicence />} />
            <Route
              path="foreign-licence-conversion-to-malawi"
              element={<ForeignLicenceConversionToMalawi />}
            />
            <Route
              path="learner-licence-test"
              element={<LearnerLicenceTest />}
            />
          </Route>
          {/* driving licence route and subroutes close*/}

          {/* driving permits route and subroutes */}
          <Route path="driving-permits">
            <Route index element={<Drivingpermits />} />
            <Route
              path="international-driving-permit-application"
              element={<InternationalDrivingPermitApplication />}
            />
            <Route
              path="proffessional-driving-application"
              element={<ProffessionalDrivingApplication />}
            />
            <Route
              path="proffessional-driving-permit-duplicate"
              element={<ProffessionalDrivingPermitDuplicate />}
            />
            <Route
              path="proffessional-driving-permit-renewal"
              element={<ProffessionalDrivingPermitRenewal />}
            />
          </Route>
          {/* driving permits route and subroutes close*/}

          {/* driving entity enrollment route and subroutes  */}
          <Route path="entity-enrollment">
            <Route index element={<Entityenrollment />} />
            <Route path="body-of-person" element={<BodyofPerson />} />
            <Route path="minor" element={<Minor />} />
            <Route path="natural-persons" element={<NaturalPersons />} />
          </Route>
          {/* driving entity enrollment route and subroutes close*/}

          {/* driving operator permits route and subroutes  */}
          <Route path="operator-permits">
            <Route index element={<Operatorpermits />} />
            <Route
              path="duplication-of-operator-permit"
              element={<DuplicationOfOperatorPermit />}
            />
            <Route
              path="operator-permit-application"
              element={<OperatorPermitApplication />}
            />
            <Route
              path="operator-registration"
              element={<OperatorRegistration />}
            />
            <Route
              path="renewal-of-operator-permit"
              element={<RenewalOfOperatorPermit />}
            />
            <Route
              path="upliftment-of-suspended-permit"
              element={<UpliftmentOfSuspendedPermit />}
            />
          </Route>

          {/* driving operator permits route and subroutes close*/}

          {/* driving vehicle applications route and subroutes  */}
          <Route path="vehicle-applications">
            <Route index element={<Vehicleapplications />} />
            <Route
              path="certificate-of-fitness"
              element={<CertificateOfFitness />}
            />
            <Route path="change-of-ownership" element={<ChangeOfOwnership />} />
            <Route
              path="change-of-vehicle-particulars"
              element={<ChangeOfVehicleParticulars />}
            />
            <Route path="change-of-vehicle" element={<ChangeOfVehicle />} />
            <Route
              path="duplicates-of-certificates"
              element={<DuplicatesOfCertificates />}
            />
            <Route
              path="notification-of-sale"
              element={<NotificationOfSale />}
            />
            <Route
              path="public-service-registration-plates"
              element={<PublicServiceRegistrationPlates />}
            />
            <Route
              path="vehicle-deregistration"
              element={<VehicleDeregistration />}
            />
            <Route
              path="vehicle-registration"
              element={<VehicleRegistration />}
            />
          </Route>
          {/* driving vehicle applications route and subroutes close*/}

          {/* Routes for the Outsorcing Inspection */}
          <Route path="Vehicle_Inspection">
          <Route index  element={<VehicleInspection/>}/>
          <Route path='Lilongwe' element={<Lilongwe/>}/>
          <Route path='Blantyre' element={<Blantyre/>}/>
          <Route path='Mzuzu' element={<Mzuzu/>}/>
          </Route>
          

          {/* driving infrastructure route and subroutes  */}
          <Route path="infrastructure">
            <Route index element={<Infrastructure />} />
            <Route path="driving-instructor" element={<DrivingInstructor />} />
            <Route
              path="driving-school-registration"
              element={<DrivingSchoolRegistration />}
            />
            <Route
              path="trade-entity-registration"
              element={<TradeEntityRegistration />}
            />
          </Route>
          {/* driving infrastructure route and subroutes close*/}
        </Route>

        <Route path="/service-Center" element={<Service />} />
        <Route path="/media-center" element={<Media />} />

        {/* Downloads route amd  */}
        <Route path="/downloads" element={<Downloads />}>
        <Route path='DownloadIndex' element={<DownloadIndex/>}/>
        <Route path='Regulations' element={<Regulations/>}/>
        <Route path='Publications' element={<Publications/>}/>
        <Route path='PressRelease' element={<PressRelease/>}/>
        <Route path='Embosser' element={<Embosser/>}/>
        <Route path='RoadTraffic' element={<RoadTraffic/>}/>
        </Route>

      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
