import React from 'react'

function vehicle_registration() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Vehicle Registration</h1>

<div className="inline-block p-4">
<p>
A motor vehicle must be introduced by the Malawi Revenue Authority (Customs Clearance Certificate or CCC), cleared by Interpol (Police Clearance Certificate or PCC) and registered at the DRTSS by both the vehicle's Title Holder and Owner before any transactions relating that vehicle can be done on MalTIS.
  </p>
  <p>All vehicles must be registered within 14 days of the date of introduction (receiving the CCC) by the MRA or will be penalized for late registration. Registration is required in order to assist in positively identifying the Title Holder and Owner of the vehicle.</p>
<h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Remember that the Title Holder and Owner must be enrolled on MalTIS before registering a vehicle. For instructions on how to enroll please click here: NATURAL PERSONS / BODY OF PERSONS
Remember the title holder and owner of the vehicle or their proxies must be present to provide biometric verification.

</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-2 text-sm m-4'>CHECKLIST FOR VEHICLE REGISTRATION. DO YOU HAVE?</h4>
        <li>Positive identification of the vehicle's:
        <li className='pl-5'>Chassis number</li>
        <li className='pl-5'>Register number (VRN)</li>
        <li className='pl-5'>Engine number</li>              
        </li>

        <li>Traffic Register Card/Certificate of the vehicle's Title Holder</li>
        <li>Traffic Register Card/Certificate of the vehicle's Owner</li>
        <li>Completed VEHICLE REGISTRATION form.</li>
       
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        REGISTRATION PROCESS
      </p>

        <li>
        To avoid filling in forms in the queue, download the VEHICLE REGISTRATION form now and complete it before you arrive at the DRTSS office.
        </li>
        <li>
        Visit your nearest DRTSS OFFICE.
        </li>
        <li>
        Go to the Vehicle Application Office.
        </li>
        <li>Submit your application at the counter.</li>
        <li>If all is in order the vehicle will be registered to a title holder and owner and a standard vehicle registration number will be allocated. If you require a Special, Vanity, Out-of-Sequence or Next-in-Sequence Registration Number please click here: SPECIAL / VANITY REGISTRATION NUMBER APPLICATION.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
          <li  className='pl-5'>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
        <li>Go to the Document Issuing Office. The title holder will receive the vehicle registration certificate (Blue Book) and the owner will receive the authorization to purchase registration plates.</li>
        
  </div>  
  </div>
  )
}

export default vehicle_registration
