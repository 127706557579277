import React from 'react';
function GovStructure() {
  return (
    <>
      <div className="text-center min-h-screen p-2 mt-4">
        <p>Gov structure</p>
      </div>
    </>
  );
}

export default GovStructure;
