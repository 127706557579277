import React from 'react'
import Main from '../media/main pic.JPG'
function regulations() {
  return (
    <div>
      <img className='w-fit h-100%' alt='' src={Main}/>
    </div>
  )
}

export default regulations
