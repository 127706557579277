import React from 'react';
function entityenrollment() {
  return (
    <>
      <p> welcome to the ernolment page</p>
    </>
  );
}

export default entityenrollment;
