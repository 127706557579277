import React from 'react';
import DrivingIndex from './DrivingIndex';

function drivinglicense() {
  return (
   <>
    <DrivingIndex/>
   </>
  );
}

export default drivinglicense;
