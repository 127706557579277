import React from 'react';
import SideMenu from './SideMenu';


const sideBar =() => {

  return (
      <div className='fixed  left-0 top-[50px]  bottom-0 bg-white border-r-4 border-indigo-100 w-[20%] h-100%  p-[20px] overflow-auto'>
      <SideMenu/>
      </div> 
  )
}

export default sideBar
