import React from 'react';
import { Outlet } from 'react-router-dom';
import Nav from '../Components/Nav';
import AboutSideBar from './AboutSideBar';

const About = () => {
  return (
    <>
      <Nav />
      <AboutSideBar/>
      <div className="inline-block ml-[20%] w-4/5 bg-[#1e0e66]">
      <Outlet/>
      </div>
      
    </>
  );
};

export default About;
