import React from 'react'

function change_of_ownership() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Title Holder Conveyance / Change of ownership</h1>

<div className="inline-block p-4">
<h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Note: You have to be enrolled on MalTIS before applying for a vehicle’s title holder conveyance or change of ownership. For instructions on how to enrol please click here: NATURAL PERSONS / BODY OF PERSONS
before the Change of Ownership can be processed.<br/>
Note: Both the new Title Holder and new Owner (or proxy of either) must be present for biometric verification.
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-2 text-sm m-4'>CCHECKLIST FOR TITLE HOLDER CONVEYANCE AND/OR CHANGE OF OWNERSHIP. DO YOU HAVE?</h4>
        <li>Traffic Register Card/Certificate of new title holder and owner</li>
        <li>Vehicle registration certificate, of vehicle being sold</li>
        <li>Both the title holder and owner (or proxy of either) must be present for biometric verification</li>
        <li>Completed CHANGE OF OWNERSHIP APPLICATION form.</li>              
        
        <p className='bg-blue-800 p-2 m-4 w-fit'>
        APPLICATION PROCESS
      </p>

        <li>To avoid filling in forms in the queue, download the CHANGE OF OWNERSHIP APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
        <li>Visit your nearest DRTSS OFFICE.</li>
       

        <li>
        Go to the Vehicle Application office.
        </li>
        <li>
        Submit your application form and supporting documents at the counter.
        </li>
        <li>
        A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li className='pl-5'>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip.</li>
        <li>Go to the Document Issuing Office to collect your registration certificate. Note: Only the new Title Holder may collect the vehicle registration certificate.</li>
        <p className='bg-blue-800 p-2 m-4 w-fit'>
        NOTE: For Public Service, Vanity, Out-of-Sequence and Next-in-Sequence Registration Numbers, click here: PUBLIC SERVICE/VANITY REGISTRATION NUMBERS
      </p>
  </div>  
  </div>
  )
}

export default change_of_ownership
