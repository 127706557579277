import React from 'react'

function renewal_of_operator_permit() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Renewal of Operator Permits</h1>

<div className="inline-block p-4">
<p>
Any operator who wishes to convey goods or passengers for reward within Malawi or across the border needs to apply for a local- or cross-border road transportation permit. A permit will not be renewed if the operator is presently suspended or has any outstanding contraventions.

  </p>
  <p>The following permits cannot be renewed: Abnormal, Short-term- and Special Journey Permits. New applications for these permits must be completed. A new permit will not be issued if the operator is presently suspended or has any outstanding infringement notices.</p>
<h4 className='bg-blue-800 w-fit text-white rounded-md p-1 text-sm mt-2'>CHECKLIST FOR A RENEWAL OF A PERMIT. DO YOU HAVE?
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>

        <li>Traffic Register card/certificate of operator.</li>
        <li>Completed OPERATOR PERMIT APPLICATION form.</li>
        <li>The details of the permit to be renewed.</li>
        <h4 className='bg-blue-800 w-fit text-white rounded-md p-1 text-sm mt-2'>APPLICATION PROCESS
</h4>
<li>o avoid filling in forms in the queue, download and print the OPERATOR PERMIT APPLICATION FORM form now and complete it before you arrive at the</li>
        <li>Visit your nearest DRTSS OFFICE.</li>
        <li>Go to the Vehicle Applications Office or Vehicle Examiner’s Office.</li>
        <li>Submit your application at the counter.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip and collect your operator permit.</li>
  </div>
  </div>
  )
}

export default renewal_of_operator_permit
