import React from 'react'

function learner_licence_test() {
  return (
    <div>
      
    <h1 className="p-4 font-semibold text-2xl"> Schedule Learner License Test</h1>
  
    <div className="inline-block p-4">
    A valid learner license is one of the prerequisites for scheduling a driving license test.
    A person can apply for a learner license test for a specific vehicle category provided he/she is of the right age
    and has received the minimum number of theoretical lessons at a registered driving school.

    <h4 className='bg-blue-800 w-100% text-white w-100% rounded-md p-1 text-sm mt-2'>Note: You have to be enroled on MalTIS before applying to schedule a Learner Licence test. For instructions on how to enrol please click here NATURAL PERSONS</h4>
    </div>

    <div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
    <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-2 text-sm m-4'>CHECKLIST FOR SCHEDULING LEARNER LICENCE TEST. DO YOU HAVE?</h4>
            <li>Traffic Register Card - received when you enroll on MalTIS. </li>
            <li>A minimum of 20 lessons in a minimum period of 7 days at a registered Driving School for first time applicants,
             and a minimum of 10 lessons in a minimum period of 7 days for any subsequent applications - The Driving School must record the number of lessons completed on MalTIS</li>
            <li>A completed LEARNER LICENCE APPLICATION form.</li>
            <li>A valid eye-test is required. This may be conducted at the DRTSS office or should you wish to visit your Optometrist instead,
               please let him/her complete a Vision Certificate to be verified by the DRTSS Staff administering the eye test.</li>
           <li>If you are disabled or over the age of 65, please bring along a MEDICAL CERTIFICATE completed by a Medical Practitioner, clearing you to drive.</li>
            
            <p className='bg-blue-800 w-100% text-white p-2 m-4 w-fit'>
            APPLICATION PROCESS
          </p>

            <li>To avoid filling in forms in the queue, download and print the LEARNER LICENCE APPLICATION form now and complete it before you arrive at the DRTSS office</li>
            <li>Visit your nearest DRTSS OFFICE</li>
            <li>
              Go to the Eye Test Station to undergo an Eye Test or submit a Vision Certificate
              <li>If you have already completed and passed an Eye Test at the DRTSS office within the past 6 (SIX) months, you will not be required to complete another Eye Test.</li>
            </li>
            <li className='text-left decoration-none'>If you have undergone an eye test at an Optometrist you
               are not required to complete another eye test at the DRTSS.
                Note that a Vision Certificate must be submitted as proof of the examination.</li>
            <li>Submit your application at the Person Applications counter</li>
            <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank.
               No money is to be paid at the DRTSS office.
               <li>a.	Please verify that the deposit slip is correct and reflects your details as well as an accurate description
                 of the transaction you wish to complete before making the required payment at NBS Bank.</li>
               </li>
            <li>Once payment is made, you may return to the DRTSS office with the stamped deposit slip. </li>
            <li>Go to the Document Issuing Office to collect your Learner License.</li>
            
            <h4 className='bg-blue-800 w-100% text-white w-100% rounded-md p-1 text-sm mt-2'>NOTE - The Leaner License is valid for 6 (SIX) months after the date of issue.
              NOTE - Should you fail the aptitude test, you will be able to reschedule it following the same process.</h4>
      </div>
    
  </div>
  )
}

export default learner_licence_test
