import React from 'react';
function Divisions() {
  return (
    <>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 p-4'>

        <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
          <a href='https://drive.google.com/uc?export=download&id=1Kr754-SFyAPOpE6Q1CSVjIUXRZgVPygM'>Basic road safety Handbook</a>
        </div>
        <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
          <a href='https://drive.google.com/uc?export=download&id=166dl3IXWI5Kagat2U7sNS3kX6Yat5f6n'>Client Service Charter</a>
        </div>
        <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
          <a href='https://drive.google.com/uc?export=download&id=1RVx9O7NIcv8L0QmjAt5TpoIjum90qRjj'>Corruption and fraud prevention</a>
        </div>
        <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
          <a href='https://drive.google.com/uc?export=download&id=1uis_ofpdMfzpMAm4Pq45H7iwA6HrdmDm'>Infant Road safety Manual</a>
        </div>
        <div className='w-full  bg-slate-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500 border-b-4 border-blue-400'>
          <a href='https://drive.google.com/uc?export=download&id=1bvd5GUNPuWQQh_bHIk-18JPllPuSoNYQ'>Road safety pack for competency based training of motorcycle riders</a>
        </div>
      </div>
    </>
  );
}

export default Divisions;
