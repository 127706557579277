import React from 'react'

function operator_permit_application() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Operator Permits</h1>

<div className="inline-block p-4">

<h4 className='bg-blue-800   w-fit text-white rounded-md p-1 text-sm mt-2'>PERMIT TYPES:
</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>

        <li>OPERATOR ROAD SERVICE PERMITS</li>
        <li>OPERATOR CROSS BORDER PERMITS</li>
        <li>OPERATOR ABNORMAL PERMITS</li>
        <h4 className='bg-blue-800   w-fit text-white rounded-md p-1 text-sm mt-2'>PREREQUISITES FOR ALL PERMITS:
</h4>
<li>The Entity must be registered as an Operator on MalTIS: OPERATOR REGISTRATION.</li>
        <li>Operator vehicles must have a valid CERTIFICATE OF FITNESS.</li>
        <li>The vehicle must have the correct usage ie. Goods, Passengers for Reward, Hire Car or Taxi.</li>
        <h4 className='bg-blue-800   w-fit text-white rounded-md p-1 text-sm mt-2'>CHECKLIST FOR A NEW PERMIT. DO YOU HAVE?</h4>
        <li>Traffic Register card/certificate of Operator.</li>
        <li>Completed OPERATOR PERMIT APPLICATION form.</li>
       <h4 className='bg-blue-800   w-fit text-white rounded-md p-1 text-sm mt-2'>APPLICATION PROCESS</h4>
       <li>To avoid filling in forms in the queue, download and print the OPERATOR PERMIT APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
        <li>Visit your nearest DRTSS OFFICE.</li>
        <li>Go to the Vehicle Applications Office or Vehicle Examiner’s Office.</li>
        <li>Submit your application at the counter.</li>
        <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
        <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment.</li>
        </li>
        <li>Once the payment is made, you may return to the DRTSS office with the stamped deposit slip and collect your Road Service Permit.</li>
  </div>
  </div>
  )
}

export default operator_permit_application
