import React from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/footer';
function servicepage() {
  return (
    <div>
      <Nav/>
      <div className='mb-4 bg-[#1e0e66] text-white '>
        <div className="max-w-[880px] w-full  mx-auto text-center flex flex-col justify-center">
          <h1 className="md:text-3xl sm:-3xl text-2xl font-bold p-4  border-indigo-200 border-b-indigo-500">
            OPERATIONAL SERVICE CENTRES
          </h1>

          <p className="p-4">
            The Directorate has a Secretariat in Lilongwe along Paul Kagame Road
            and four Regional offices in Lilongwe, Blantyre, Zomba and Mzuzu.
            Central and Southern regions have satellite offices in Lilongwe at
            City Centre and Blantyre at Makata respectively.
          </p>
            <hr/>
         <h1 className="md:text-3xl sm:-3xl text-2xl font-bold p-4">  SATELLITE CENTRES </h1>
          <p className='p-4'>
            DRTSS Central and Southern Regional Offices have satellite offices in Lilongwe at City Centre and Blantyre at Makata respectively. </p>
            <hr/>
        </div>
        <div className='flex justify-center text-center'><h1 className="md:text-3xl sm:-3xl text-2xl font-bold ">FIND US </h1></div>
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-4 gap-4 p-4">
          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe  className='rounded-md' id="gmap_canvas" title="CFAO" src="https://maps.google.com/maps?q=DRTSS%20Head%20Office,liongwe&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
            <h1 className='p-2 text-center'>DRTSS Head Office</h1>
            <p className="p-3 text-bold text-center">
            Along Paul Kagame Highway, Near CFAO Malawi,
            </p>
          </div>
      
          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe  id="gmap_canvas"   title="ginnery" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8743.60130498158!2d35.018346086162765!3d-15.804366861122707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18d8459316649e7d%3A0xf1eb705691e84132!2sGinnery%20Corner%2C%20Blantyre%2C%20Malawi!5e0!3m2!1sen!2sus!4v1677704580076!5m2!1sen!2sus" />
            <h1 className='p-2 text-center'>DRTSS Regional Office South,</h1>
            <p className="p-3 text-bold text-center">
            At Ginnery Corner, ESCOM Power Station Road,
            </p>
          </div>
          
          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe id="gmap_canvas"  title="statistics" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9417.923624488505!2d35.317860140620276!3d-15.37521473830009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18d904c82f284d2b%3A0xe230abd264acb2c3!2sNational%20Statistical%20Office%20(NSO)%3A%20Publications%20and%20Library!5e0!3m2!1sen!2sus!4v1677705123861!5m2!1sen!2sus" />
            <h1 className='p-2 text-center'>DRTSS Regional Office East</h1>
            <p className="p-3 text-bold text-center">
            Behind National Statistics,
            </p>
          </div>
          
          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe className='rounded-md' id="gmap_canvas"  title="mutual" src="https://www.google.com/maps/embed/v1/place?q=2Q99+GF7,+Chilambula+Rd,+Lilongwe,+Malawi&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
            <h1 className='p-2 text-center'> DRTSS Regional Office Centre,</h1>
            <p className="p-3 text-bold text-center">
            Along Paul Kagame Highway, Next to Corporate Mall,
            </p>
          </div>

          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe   title="office north" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3910.202916542938!2d34.02053441411123!3d-11.465274591856318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x191d3bab923bcbcd%3A0xaeea6483c4272b13!2sMzuzu%20Panel%20Beaters%20Workshop!5e0!3m2!1sen!2sus!4v1677705380791!5m2!1sen!2sus" />
            <h1 className='p-2 text-center'>DRTSS Regional Office North,</h1>
            <p className="p-3 text-bold text-center">
            Kanjedza Street, Opposite Mzuzu Panel Beaters,
            </p>
          </div>
          

          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe id="gmap_canvas"   title="makata" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d15357.148858711615!2d35.02272626026609!3d-15.788802468627003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sMakata%20Service%20Station!5e0!3m2!1sen!2sus!4v1677704882382!5m2!1sen!2sus" />
            <h1 className='p-2 text-center'>Makata Service Station,</h1>
            <p className="p-3 text-bold text-center">
            Makata Industrial Area,
            </p>
          </div>

          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe id="gmap_canvas"  title="post office" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.060171508087!2d35.265708314151546!3d-14.481234289878515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18dee3c88ee7c8e1%3A0x62bd4237e90d74c1!2sMangochi%20Post%20Office!5e0!3m2!1sen!2sus!4v1677705272155!5m2!1sen!2sus"  />
            <h1 className='p-2 text-center'>Mangochi One Stop Service Delivery Centre</h1>
            <p className="p-3 text-bold text-center">
            Mangochi Post Office
            </p>
          </div>

          <div className="w-full border flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500">
          <iframe className='rounded-md' id="gmap_canvas"  title="mutual" src="https://maps.google.com/maps?q=City%20Centre%20Service%20Station,lilongwe&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
            <h1 className='p-2 text-center'>City center service station,</h1>
            <p className="p-3 text-bold text-center">
            Mpico Building, Next to Old Mutual,
            </p>
          </div>
        </div>
          
        <div className="max-w-[880px] w-full  mx-auto text-center  justify-center p-2">
        <h1 className="md:text-3xl sm:-3xl text-2xl font-bold p-4  border-indigo-200">
            TOLL FREE LINE
          </h1>
            <p className='bg-white text-[#1e0e66] hover:bg-white w-fit mx-auto transition duration-100 ease-in-out text-2xl rounded-md  p-2'>4040 for Airtel Subscribers only</p>
        </div>
      
        
    
      </div>
      <Footer/>
    </div>
  );
}

export default servicepage;


