import React from 'react';

function DRTSSProfile() {
  return (
    <>
      <div className="text-center min-h-screen p-2 text-black">
        <p>DRTSS profile</p>
      </div>
    </>
  );
}

export default DRTSSProfile;
