import React from 'react'

function foreign_licence_conversion_to_malawi() {
  return (
    <div>
      
    <h1 className="p-4 font-semibold text-2xl">Foreign Driving Licence Conversion to Malawian</h1>
  
    <div className="inline-block p-4">
      <p className='mb-4'>If you are in possession of a foreign Driving Licence ,
         you want to convert it to a Malawian Driving Licence, 
         you may do so by applying for the conversion at any DRTSS Office.
          It is important to note that the application must be accompanied by both a valid foreign Driving Licence and an International Driving Permit (IDP) of any convention (either 1949 or 1968),
           where after both these documents will be destroyed upon you being issued your Malawian licence.</p>
           <p className='mb-4'>If you have recently moved to Malawi, you are required by law to convert your foreign licence to a Malawian equivalent after 6 (SIX) months of taking up permanent residence in Malawi.</p>
           <p className='mb-4'>If you are not taking up residence in Malawi, but will be visiting Malawi for an extended period, you may convert your foreign licence to a Malawian equivalent at any time.</p>
           <p>You will be required to pass a theoretical aptitude test for the driving licence conversion.</p>
    <h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>
    Remember that you have to be enroled on MalTIS before applying for a Driving licence duplicate. For instructions on how to enrol please click here: NATURAL PERSONS</h4>
    </div>

    <div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
    <h4 className='bg-blue-800 w-100% text-white w-fit  rounded-md p-2 text-sm m-4'>CHECKLIST FOR SCHEDULING LEARNER LICENCE TEST. DO YOU HAVE?</h4>
            <li>Traffic Register Card - received when you enrol on MalTIS</li>
            <li>A valid foreign Driving Licence AND International Driving Permit.</li>
            <li>Completed DRIVING LICENCE APPLICATION form.</li>
            <li>An eye-test might be conducted at the DRTSS office,
               should you have difficulty seeing and require glasses or contact lenses please remember to bring them with.
                Should you wish to visit your Optometrist instead,
                 please let him/her complete an VISION CERTIFICATE to be verified by the DRTSS Staff administering the eye test.</li>
                 <li>If you are disabled or over the age of 65,
                   please bring along a MEDICAL CERTIFICATE completed by a Medical Practitioner,
                    clearing you to drive.</li>
            
            <p className='bg-blue-800 w-100% text-white p-2 m-4 w-fit'>
            APPLICATION PROCESS
          </p>

            <li>To avoid filling in forms in the queue, download and print the DRIVING LICENCE APPLICATION FORM now and complete it before you arrive at the DRTSS office.</li>
            <li>
              Visit your nearest DRTSS OFFICE.
            </li>
              <li>
              Submit your application at the Person Applications counter.
              </li>
              <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.
              <li>Please verify that the deposit slip is correct and reflects your details as well as an accurate description of the transaction you wish to complete before making the required payment at NBS Bank.</li>
              </li>
                <li>5.	Once payment is made, you may return to the DRTSS office with the stamped deposit slip, to collect your duplicate Learner Licence, Driving Licence card or PrDP card from the Document Issuing Office.</li>
            <h4 className='bg-blue-800 w-100% text-white w-100%  rounded-md p-1 text-sm mt-2'>NOTE – Once the duplicate licence has been printed, the original licence will be cancelled and become invalid. The original validity period will remain unchanged for the duplicate licence.</h4>
      </div>
        {/* <a href='https://docs.google.com/document/d/1gamJNWgwtxs6PABD6KiLDcwh2jht-J7K/edit?usp=share_link&ouid=110038740286604154609&rtpof=true&sd=true'> form</a>
        <a href='https://docs.google.com/document/d/1V3B4OQ_kh3YWW7t5bppxSjQ0VZ04ZJkr/edit?usp=share_link&ouid=110038740286604154609&rtpof=true&sd=true'> other form</a> */}
  </div>
  )
}

export default foreign_licence_conversion_to_malawi
