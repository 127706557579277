import React from 'react'

function duplicates_of_certificates() {
  return (
    <div>
    <h1 className="p-4 font-semibold text-2xl">Duplication of Certificates</h1>

<div className="inline-block p-4">
<p>
Please note that a duplicate of any certificate will not be issued if a valid police report does not accompany the application, nor if the Title Holder or Owner has any outstanding contraventions.
  </p>
  
<h4 className='bg-blue-800 w-100% text-white rounded-md p-1 text-sm mt-2'>Remember: The title holder or owner (or proxy of either) of the vehicle must be present to provide biometric verification.
Remember: A certificate will not be duplicated if the Title Holder or Owner of the vehicle has any outstanding offenses.

</h4>
</div>

<div className='bg-slate-100/50 mt-2 w-50% rounded-md inline-block m-[20px] p-4'>
<h4 className='bg-blue-800 w-fit text-black rounded-md p-2 text-sm m-4'>The following Certificates can be duplicated in case the original was lost, stolen or destroyed:</h4>
        <li>Vehicle registration certificate</li>
        <li>Certificate of fitness (COF)</li>
        <li>Authorisation to purchase registration plates</li>            
        <p className='bg-blue-800 p-2 m-4 w-fit'>
        RULES FOR DUPLICATION OF CERTIFICATES:
      </p>

        <li>
        Outstanding offenses will prevent you from applying for duplicate certificates.
        </li>
        <li>
        Title holders may apply for duplicate:
        <li>Vehicle registration certificate.</li>
        <li>Certificate of fitness (COF).</li>
        <li>Number plate authorisation.</li>
        </li>
        <li>
        Vehicle owners may apply for:
        <li>Certificate of fitness (COF).</li>
        <li>Number plate authorisation.</li>
        </li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        CHECKLIST FOR DUPLICATE CERTIFICATES. DO YOU HAVE?
      </p>
        <li>Traffic register Card/Certificate.</li>
        <li>Vehicle registration number.</li>
        <li>Completed VEHICLE REGISTRATION APPLICATION form.</li>
        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>APPLICATION PROCESS</p>
          <li>To avoid filling in forms in the queue, download and print the VEHICLE REGISTRATION APPLICATION form now and complete it before you arrive at the DRTSS office.</li>
          <li>Visit your nearest DRTSS OFFICE.</li>
          <li>Go to the Vehicle Applications Office.</li>
          <li>Submit your application at the counter.</li>
          <li>A deposit slip will be printed which will indicate the AMOUNT to be paid at the bank. No money is to be paid at the DRTSS office.</li>

        <p className='bg-blue-800 text-white p-2 m-4 w-fit'>
        NOTE – A submission of a Notification of Sale is not a Title Holder Conveyance or Change of Ownership transaction. It is merely a notification that the vehicle was sold and that a new Title Holder and Owner may be identified.
      </p>
  </div>  
  </div>
  )
}

export default duplicates_of_certificates
